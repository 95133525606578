import * as types from "../types/CandidateProfileSetup";

const INITIAL_STATE = {
  validationFields: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CANDIDATE_PROFILE_SETUP_RECIEVE:
      return {
        ...state,
        validationFields:action.payload,
      };
    case types.EDIT_CANDIDATE_PROFILE_SETUP_RECEIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
