
import { call, put } from 'redux-saga/effects';
import API from '../../network/apis/notificationApi';
import * as ACTION from '../actions/notification';
import * as type from '../types/notification';
import store from '../../store';
import history from '../../routes/History';
import {
  dispatchError,
  showSnackbar,
} from '../../utils/shared';
import { takeLatest } from 'redux-saga/effects';

export function* popupNotificationRequest() {
    try {
      if (localStorage.getItem("token")) {
        const response = yield call(API.getNotificationPopupRequest);
        yield put(ACTION.popupNotificationResponse(response.data.data));
      }
    } catch (err) {
      dispatchError(err.response?.data);
    }
  }

export function* setNotificationVistedRequest({payload}){
  try {
    const response = yield call(API.setNotificationVistedRequest,payload);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* notificationListRequest({payload}){
  try {
    const response = yield call(API.notificationListRequest,payload);
    yield put(ACTION.notificationListResponse(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* setNotificationSeenRequest(){
  try {
    const response = yield call(API.setNotificationSeenRequest);
    yield put(ACTION.popupNotificationRequest())
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* NotificationSagasWatch() {
    yield takeLatest(type.POPUP_NOTIIFCATION_REQUEST, popupNotificationRequest);
    yield takeLatest(type.SET_NOTIFICATION_VISITED_REQUEST, setNotificationVistedRequest);
    yield takeLatest(type.NOTIFICATION_LIST_REQUEST, notificationListRequest);
    yield takeLatest(type.SET_NOTIFICATION_SEEN, setNotificationSeenRequest);
    
    
  }
  