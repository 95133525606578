import * as types from "../types/applicantProfile";

const INITIAL_STATE = {
  profile: {},
  screening: {},
  mainData: {},
  applications: {},
  preOfferAttchements: [],
  candidateMainData: {},
  interviews: [],
  log: [],
  allApplicantLogs: [],
  disableAdvert: null,
  moreInfoAnswers: [],
  offers: [],
  contracts: [],
  contractPDFFile: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_CONTRACT_FILE_BYTES_RECIEVE:
      return {
        ...state,
        contractPDFFile: action.payload,
      };
    case types.GET_APPLICANT_PROFILE_RECEIVE:
      return { ...state, profile: { ...action.payload } };
    case types.GET_APPLICATION_RECEIVE:
      return { ...state, screening: { ...action.payload } };
    case types.GET_APPLICANT_MAIN_DATA_RECEIVE:
      return { ...state, mainData: { ...action.payload } };
    case types.GET_CANDIDATE_APPLICATIONS_RECEIVE:
      return { ...state, applications: { ...action.payload } };
    case types.GET_APPLICANT_PREOFFER_ATTACHEMENTS_RECEIVE:
      return { ...state, preOfferAttchements: [...action.payload] };
    case types.GET_APPLICANT_INTERVIEWS_RECEIVE:
      return { ...state, interviews: [...action.payload] };
    case types.GET_CANDIDATE_LOG_RECEIVE:
      return { ...state, log: [...action.payload] };

    case types.GET_ALL_CANDIDATE_LOGS_RECEIVE:
      return { ...state, allApplicantLogs: [...action.payload] };
    case types.DISABLE_ADVERT_RECEIVE:
      return {
        ...state,
        disableAdvert: action.payload,
      };
    case types.GET_MORE_INFO_ANSWERS_RECEIVE:
      return {
        ...state,
        moreInfoAnswers: action.payload,
      };
    case types.GET_APPLICANT_OFFERS_RECEIVE:
      return {
        ...state,
        offers: action.payload,
      };
      case types.GET_APPLICANT_CONTRACTS_RECEIVE:
      return {
        ...state,
        contracts: action.payload,
      };
    case types.DISABLE_SEND_EMAIL_RECIEVE:
      return {
        ...state,
        disableSendEmail: Boolean(action.payload),
      };
    case types.DISABLE_SEND_MORE_INFO_RECIEVE:
      return {
        ...state,
        disableSendMoreInfoRequest: Boolean(action.payload),
      };
      case types.GET_CANDIDATE_MAIN_DATA_RECEIVE:
        return { ...state, candidateMainData: { ...action.payload } };
    default:
      return state;
  }
};
