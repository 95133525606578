import { call, put } from "redux-saga/effects";
import API from "../../network/apis/videoInterview";
import * as ACTION from "../actions/videoInterview";
import history from "../../routes/History";
import { dispatchError, showSnackbar, getURLParams } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/videoInterview";
import { myInterviewsRequest } from "store/actions/MyInterviews";

function* startInterviewRequest({ payload }) {
  try {
    const response = yield call(API.startInterviewRequest, payload);
    yield put(
      ACTION.startInterviewReceive({
        startInterview: true,
        data: response.data.data,
      })
    );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

function* endSessionRequest({ payload }) {
  try {
    yield call(API.endSession, payload);
    showSnackbar("savedSuccessfuly");
    yield put(ACTION.endSessionRecieve(true));
  } catch (err) {
    dispatchError(err.response?.data);
    yield put(ACTION.disableFinishInterviewBtn(false));
  }
}
function* finishInterviewRequest({ payload }) {
  try {
    yield call(API.finishInterviewRequest, payload);
    yield put(ACTION.resetEvaluationForm(true));
    showSnackbar("savedSuccessfuly");
    yield put(ACTION.toggleInterviewReviewModal(false));
    // redirect to reschedule page
    if (payload.reschedule) {
      if (localStorage.getItem("reschedule")) {
        window.location.href = `/manageCandidates/${
          JSON.parse(localStorage.getItem("reschedule")).job_request_id
        }`;
      }
    }
    // redirect to reschedule page
    const pageNumber = getURLParams("page") || 1;
    history.push(`/myInterviews?page=${pageNumber}&type=new`);
    yield put(myInterviewsRequest(pageNumber));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
function* interviewDurationRequest({ payload }) {
  try {
    yield call(API.interviewDurationRequest, payload);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

function* leaveInterviewRequest({ payload }) {
  try {
    yield call(API.leaveInterviewRequest, payload);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

function* evalutionTypesRequest({ payload }) {
  try {
    const response = yield call(API.requestEvalutionTypes, payload);
    yield put(ACTION.evalutionTypesReceive(response.data));

  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* VideoInterviewSagasWatch() {
  yield takeLatest(type.START_INTERVIEW_REQUEST, startInterviewRequest);
  yield takeLatest(type.FINISH_INTERVIEW_REQUEST, finishInterviewRequest);
  yield takeLatest(type.EVALUTION_TYPES_REQUEST, evalutionTypesRequest);
  yield takeLatest(type.INTERVIEW_DURATION_REQUEST, interviewDurationRequest);
  yield takeLatest(type.END_SESSION_REQUEST, endSessionRequest);
  yield takeLatest(type.LEAVE_INTERVIEW, leaveInterviewRequest);
}
