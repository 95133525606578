export const GET_COMPETENCE_SETTINGS_REQUEST="GET_COMPETENCE_SETTINGS_REQUEST"
export const GET_COMPETENCE_SETTINGS_RECEIVE="GET_COMPETENCE_SETTINGS_RECEIVE"

export const UPDATE_COMPETENCE_SETTINGS_REQUEST="UPDATE_COMPETENCE_SETTINGS_REQUEST"
export const UPDATE_COMPETENCE_SETTINGS_RECEIVE="UPDATE_COMPETENCE_SETTINGS_RECEIVE"

export const ADD_COMPETENCE_SETTINGS_CATEGORIES_REQUEST="ADD_COMPETENCE_SETTINGS_CATEGORIES_REQUEST"
export const ADD_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE="ADD_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE"

export const DELETE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST="DELETE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST"
export const DELETE_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE="DELETE_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE"

export const GET_COMPETENCE_SETTINGS_CATEGORIES_REQUEST="GET_COMPETENCE_SETTINGS_CATEGORIES_REQUEST"
export const GET_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE="GET_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE"

export const UPDATE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST="UPDATE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST"

export const DELETE_COMPETENCE_SETTINGS_ITEMS_REQUEST="DELETE_COMPETENCE_SETTINGS_ITEMS_REQUEST"
export const DELETE_COMPETENCE_SETTINGS_ITEMS_RECEIVE="DELETE_COMPETENCE_SETTINGS_ITEMS_RECEIVE"

export const ADD_COMPETENCE_SETTINGS_ITEMS_REQUEST="ADD_COMPETENCE_SETTINGS_ITEMS_REQUEST"

export const UPDATE_COMPETENCE_SETTINGS_ITEMS_REQUEST="UPDATE_COMPETENCE_SETTINGS_ITEMS_REQUEST"

export const GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_REQUEST="GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_REQUEST"
export const GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_RECEIVE="GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_RECEIVE"

export const GET_COMPETENCE_LOOKUP_REQUEST="GET_COMPETENCE_LOOKUP_REQUEST"
export const GET_COMPETENCE_LOOKUP_RECEIVE="GET_COMPETENCE_LOOKUP_RECEIVE"
