import * as types from "../types/jobTitles";

const INITIAL_STATE = {
  list: {
    data: [],
    paging: {}
  },
  successJobTitles: {},
  jobTitleDescription:null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LIST_JOB_TITLES:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          paging: action.payload.paging
        }
      };
    case types.ADD_EDIT_JOB_TITLE_RECEIVE:
      return { ...state, successJobTitles: { ...action.payload } };
      case types.GET_JOB_TITLE_DESCRIPTION_RECEIVE:
        return {
          ...state,
          jobTitleDescription: {  
             ...action.payload.data, 
          }
        } 
    default:
      return state;
  }
};
