import { axiosInstance } from "./config";

let handlerEnabled = true;

const listJobTitles = async pageNumber => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10
  };
  return await axiosInstance.get(`/admin/job_title/get`, {
    params,
    handlerEnabled
  });
};

const getJobTitleDescription  = async id => {
  return await axiosInstance.get(`/admin/job_title/${id}/description`, { handlerEnabled });
};
const addEditJobTitle = async jobTitleData => {
  return await axiosInstance.put("/admin/job_title/add_edit", jobTitleData, {
    handlerEnabled
  });
};

export default {
  listJobTitles,
  addEditJobTitle,
  getJobTitleDescription
};
