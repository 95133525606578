import { call, put } from "redux-saga/effects";
import API from "../../network/apis/CandidateProfileSetup";
import * as ACTION from "../actions/candidateProfileSetup";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/CandidateProfileSetup";
import { dispatchError, showSnackbar } from "utils/shared";

export function* candidateProfileSetupRequest() {
  try {
    const response = yield call(API.getCandidateProfileSetup);
    yield put(ACTION.candidateProfileSetupRecieve(response.data.data));
  } catch (error) {
    console.log(error);
    dispatchError(error.response?.data);
  }
}

export function* editCandidateProfileSetupRequest(action) {
  console.log(action.payload);
  try {
    const response = yield call(API.editCandidateProfileSetup, action.payload);
    yield put(ACTION.candidateProfileSetupRequest());

    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* CandidateProfileSetupSagasWatch() {
  yield takeLatest(
    type.CANDIDATE_PROFILE_SETUP_REQUEST,
    candidateProfileSetupRequest
  );
  yield takeLatest(
    type.EDIT_CANDIDATE_PROFILE_SETUP_REQUEST,
    editCandidateProfileSetupRequest
  );
}
