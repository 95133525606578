import * as types from "../types/offerTemplateSetup";

export const getOfferTemplateSettingsRequest = () => ({
  type: types.GET_OFFER_TEMPLATE_SETTINGS_REQUEST,
});
export const getOfferTemplateSettingsReceive = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_SETTINGS_RECEIVE,
  payload,
});

export const getOfferTemplateDefaultSettingsRequest = () => ({
  type: types.GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_REQUEST,
});
export const getOfferTemplateDefaultSettingsReceive = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_DEFAULT_SETTINGS_RECEIVE,
  payload,
});

export const getOfferTemplatePlaceholdersRequest = () => ({
  type: types.GET_OFFER_TEMPLATE_PLACEHOLDERS_REQUEST,
});
export const getOfferTemplatePlaceholdersReceive = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_PLACEHOLDERS_RECEIVE,
  payload,
});

export const saveOfferTemplateSettingsRequest = (payload) => ({
  type: types.SAVE_OFFER_TEMPLATE_SETTINGS_REQUEST,
  payload,
});

export const deleteOfferTemplateCoverLetterRequest = (payload) => ({
  type: types.DELETE_OFFER_TEMPLATE_COVER_LETTER_REQUEST,
  payload,
});
export const getOfferBytesRequest = (payload) => ({
  type: types.GET_OFFER_FILE_BYTES,
  payload,
})
export const getOfferBytesRecieve = (payload) => ({
  type: types.GET_OFFER_FILE_BYTES_RECIEVE,
  payload,
})