import * as types from "../types/recruitmentPlan";

export const getRecruitmentPlan = () => {
  return {
    type: types.GET_RECRUITMENT_PLAN_REQUEST,
  };
};
export const getRecruitmentPlanRecieve = (data) => {
  return {
    type: types.GET_RECRUITMENT_PLAN_RECIEVE,
    payload: data,
  };
};
export const RecruitmentPlanRequest = (data) => {
  return {
    type: types.RECRUITMENT_PLAN_REQUEST,
    payload: data,
  };
};
