import { isUserHasPermission, isSuperAdmin } from "utils/Permissions";
export const executiveDirectorHasPermissionJobRequestApproval = () => {
  return ManpowerApprovedPermissions.some((permission) =>
    isUserHasPermission(permission)
  ) || isSuperAdmin();
};
export const executiveDirectorHasPermissionJobRequestEdit = () => {
  return JobRequestPermissions.some((permission) =>
    isUserHasPermission(permission)
  ) || isSuperAdmin();
};
const ManpowerApprovedPermissions = [
  "VIEW_PENDING_JOB_REQUESTS",
  "EDIT_PENDING_JOB_REQUESTS",
  "DELETE_PENDING_JOB_REQUESTS",
  "VIEW_RECRUITER_JOBS_PENDING_ONLY",
];
const JobRequestPermissions = [
  "LIST_JOB_REQUESTS",
  "VIEW_PUBLISHED_JOB_REQUESTS",
  "COPY_PUBLISHED_JOB_REQUESTS",
  "CLOSE_PUBLISHED_JOB_REQUESTS",
  "VIEW_RECRUITER_PUBLISHED_JOBS_ONLY",
];
