import * as types from "../types/recruitmentPlan";

const INITIAL_STATE = {
  file: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_RECRUITMENT_PLAN_RECIEVE:
      return {
        ...state,
        file: action.payload,
      };
    default:
      return state;
  }
};
