import * as types from "../types/jobRoleSetup";

export const requestAddJobRole = (data, type) => {
  return {
    type: types.ADD_JOB_ROLE_REQUEST,
    payload: { data, type }
  };
};

export const receiveAddJobRole = data => {
  return {
    type: types.ADD_JOB_ROLE_RECEIVE,
    payload: data
  };
};

export const requestGetJobRole = data => {
  return {
    type: types.GET_JOB_ROLE_REQUEST,
    payload: data
  };
};

export const receiveGetJobRole = data => {
  return {
    type: types.GET_JOB_ROLE_RECEIVE,
    payload: data
  };
};

export const requestEditJobRole = data => {
  return {
    type: types.EDIT_JOB_ROLE_REQUEST,
    payload: data
  };
};

export const receiveEditJobRole = data => {
  return {
    type: types.EDIT_JOB_ROLE_RECEIVE,
    payload: data
  };
};

export const requestDeleteJobRole = data => {
  return {
    type: types.DELETE_JOB_ROLE_REQUEST,
    payload: data
  };
};

export const receiveDeleteJobRole = data => {
  return {
    type: types.DELETE_JOB_ROLE_RECEIVE,
    payload: data
  };
};

export const requestAllJobRoles = data => {
  return {
    type: types.GET_ALL_JOB_ROLES_REQUEST,
    payload: data
  };
};

export const receiveAllJobRoles = data => {
  return {
    type: types.GET_ALL_JOB_ROLES_RECEIVE,
    payload: data
  };
};

export const approvalSetupRequest = () => {
  return {
    type: types.APPROVAL_SETUP_REQUEST
  };
};

export const approvalSetupRecieve = data => {
  return {
    type: types.APPROVAL_SETUP_RECIEVE,
    payload: data
  };
};

export const saveApprovalSetupRequest = data => {
  return {
    type: types.SAVE_APPROVAL_SETUP_REQUEST,
    payload: data
  };
};

export const saveApprovalSetupRecieve = data => {
  return {
    type: types.SAVE_APPROVAL_SETUP_RECIEVE,
    payload: data
  };
};

export const jobRolesWithApprovalRequest = () => {
  return {
    type: types.JOB_ROLES_WITH_APPROVAL_REQUEST
  };
};

export const jobRolesWithApprovalRecieve = data => {
  return {
    type: types.JOB_ROLES_WITH_APPROVAL_REACIEVE,
    payload: data
  };
};

export const salaryRangeRequest = data => {
  return {
    type: types.SALARY_RANGE_REQUEST,
    payload: data
  };
};

export const salaryRangeResponse = data => {
  return {
    type: types.SALARY_RANGE_RESPONSE,
    payload: data
  };
};

export const submitApprovalSetupRequest= data => {
  return {
    type: types.SUBMIT_APPROVAL_SETUP_REQUEST,
    payload: data
  };
};

export const approvalSetupDataRequest= (data) => {
  return {
    type: types.APPROVAL_SETUP_DATA_REQUEST,
    payload: data
  };
};

export const approvalSetupDataResponse= data => {
  return {
    type: types.APPROVAL_SETUP_DATA_RESPONSE,
    payload: data
  };
};