import * as types from "../types/CandidateProfileSetup";

export const candidateProfileSetupRequest = () => {
  return {
    type: types.CANDIDATE_PROFILE_SETUP_REQUEST,
  };
};

export const candidateProfileSetupRecieve = (data) => {
  return {
    type: types.CANDIDATE_PROFILE_SETUP_RECIEVE,
    payload: data,
  };
};

export const requestEditCandidateProfileSetup = (data) => {
  return {
    type: types.EDIT_CANDIDATE_PROFILE_SETUP_REQUEST,
    payload: data,
  };
};

export const receiveEditCandidateProfileSetup = (data) => {
  return {
    type: types.EDIT_CANDIDATE_PROFILE_SETUP_RECEIVE,
    payload: data,
  };
};
