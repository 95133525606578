import React, { useEffect, useState, useRef } from "react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import Moment from "react-moment";
import "moment/locale/ar";
import "moment/locale/en-au";
import moment from "moment-timezone";
import {
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import "./popupNotification.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  popupNotificationRequest,
  setNotificationVistedRequest,
} from "store/actions/notification";
import { handleNotificationRedirection } from "../../utils/notificationShared";
import { isToday } from "../../utils/shared";
import { Link } from "react-router-dom";

const PopupNotification = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.locale.lang);
  const popupNotification = useSelector(
    (state) => state.notification.popupNotification
  );

  const handleNotificationCLick = (notification) => {
    dispatch(setNotificationVistedRequest(notification.notification_id));
    handleNotificationRedirection(notification);
  };
  return (
    <div className="popup-notification__wrapper" ref={wrapperRef}>
      <div>
        {popupNotification?.notifications?.length > 0 &&
          popupNotification?.notifications.map((notification) => (
            <List key={notification.notification_id}>
              <ListItem
                autoFocus
                button
                onClick={() => handleNotificationCLick(notification)}
                className={
                  !notification.is_visited ? "notification-unread" : null
                }
              >
                <ListItemAvatar>
                  <div className="company-img">
                    {notification.company_image_url && (
                      <img
                        src={notification.company_image_url}
                        alt="company image"
                      />
                    )}
                  </div>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className="notification-text">
                      <span className="d-block notification-text-content">
                        {lang == "en"
                          ? notification.message_en
                          : notification.message_ar}
                      </span>
                      <span className="notification-text-date">
                        {isToday(notification.send_at) ? (
                          <>
                            {lang == "en"
                              ? moment
                                  .utc(notification.send_at)
                                  .local()
                                  .locale("en")
                                  .fromNow()
                              : moment
                                  .utc(notification.send_at)
                                  .local()
                                  .locale("ar")
                                  .fromNow()}
                          </>
                        ) : (
                          <>
                            {lang == "en"
                              ? moment
                                  .utc(notification.send_at)
                                  .local()
                                  .format("DD MMM YYYY, hh:mm")
                              : moment
                                  .utc(notification.send_at)
                                  .local()
                                  .format("hh:mm, YYYY-MM-DD")}
                          </>
                        )}
                      </span>
                    </div>
                  }
                />
              </ListItem>
            </List>
          ))}
        {popupNotification?.notifications?.length == 0 && (
          <div className="no-notification">
            <NotificationsActiveIcon />
            <p>{lang == "en" ? "No notifications yet" : "لا يوجد تنبيهات"}</p>
          </div>
        )}
      </div>
      {popupNotification?.notifications?.length > 0 && (
        <div className="text-center view-all-notification">
          <Link to="/notificationList" className="text-center">
            {lang == "en" ? " View all notifications" : "عرض التنبيهات"}
          </Link>
        </div>
      )}
    </div>
  );
};

export default PopupNotification;