export const GET_JOB_ROLE_REQUEST = "GET_JOB_ROLE_REQUEST";
export const GET_JOB_ROLE_RECEIVE = "GET_JOB_ROLE_RECEIVE";
export const ADD_JOB_ROLE_REQUEST = "ADD_JOB_ROLE_REQUEST";
export const ADD_JOB_ROLE_RECEIVE = "ADD_JOB_ROLE_RECEIVE";
export const EDIT_JOB_ROLE_REQUEST = "EDIT_JOB_ROLE_REQUEST";
export const EDIT_JOB_ROLE_RECEIVE = "EDIT_JOB_ROLE_RECEIVE";
export const GET_ALL_JOB_ROLES_REQUEST = "GET_ALL_JOB_ROLES_REQUEST";
export const GET_ALL_JOB_ROLES_RECEIVE = "GET_ALL_JOB_ROLES_RECEIVE";
export const DELETE_JOB_ROLE_REQUEST = "DELETE_JOB_ROLE_REQUEST";
export const DELETE_JOB_ROLE_RECEIVE = "DELETE_JOB_ROLE_RECEIVE";

export const APPROVAL_SETUP_REQUEST = "APPROVAL_SETUP_REQUEST";
export const APPROVAL_SETUP_RECIEVE = "APPROVAL_SETUP_RECIEVE";

export const SAVE_APPROVAL_SETUP_REQUEST = "SAVE_APPROVAL_SETUP_REQUEST";
export const SAVE_APPROVAL_SETUP_RECIEVE = "SAVE_APPROVAL_SETUP_RECIEVE";

export const JOB_ROLES_WITH_APPROVAL_REQUEST = "JOB_ROLE_WITH_APPROVAL_REQUEST";
export const JOB_ROLES_WITH_APPROVAL_REACIEVE =
  "JOB_ROLE_WITH_APPROVAL_REACIEVE";

export const SALARY_RANGE_REQUEST="SALARY_RANGE_REQUEST"
export const SALARY_RANGE_RESPONSE="SALARY_RANGE_RESPONSE"
export const SUBMIT_APPROVAL_SETUP_REQUEST="SUBMIT_APPROVAL_SETUP_REQUEST"
export const APPROVAL_SETUP_DATA_REQUEST="APPROVAL_SETUP_DATA_REQUEST"
export const APPROVAL_SETUP_DATA_RESPONSE="APPROVAL_SETUP_DATA_RESPONSE"