export const START_INTERVIEW_REQUEST = "START_INTERVIEW_REQUEST";
export const START_INTERVIEW_RECEIVE = "START_INTERVIEW_RECEIVE";

export const FINISH_INTERVIEW_REQUEST = "FINISH_INTERVIEW_REQUEST";
export const FINISH_INTERVIEW_RECEIVE = "FINISH_INTERVIEW_RECEIVE";

export const HANDLE_SUBSCRIPTION = 'HANDLE_SUBSCRIPTION'

export const INTERVIEW_DURATION_REQUEST = 'INTERVIEW_DURATION_REQUEST';

export const INTERVIEW_CHAT_MESSAGES = 'INTERVIEW_CHAT_MESSAGES';

export const TOGGLE_INTERVIEW_REVIEW_MODAL = 'TOGGLE_INTERVIEW_REVIEW_MODAL';
export const END_SESSION_REQUEST = 'END_SESSION_REQUEST';
export const END_SESSION_RECIEVE = 'END_SESSION_RECIEVE';

export const DISABLE_FINISH_INTERVIEW_BTN = 'DISABLE_FINISH_INTERVIEW_BTN'

export const LEAVE_INTERVIEW = 'LEAVE_INTERVIEW';
export const RESET_EVALUATION_FORM = 'RESET_EVALUATION_FORM'; 

export const EVALUTION_TYPES_REQUEST = "EVALUTION_TYPES_REQUEST";
export const EVALUTION_TYPES_RECEIVE = "EVALUTION_TYPES_RECEIVE";