export const RECIEVE_ORGANIZATION_STRUCTURE = "RECIEVE_ORGANIZATION_STRUCTURE";
export const REQUEST_ORGANIZATION_STRUCTURE = "REQUEST_ORGANIZATION_STRUCTURE"
export const GET_ACTIVE_AGENTS_REQUEST="GET_ACTIVE_AGENTS_REQUEST"
export const GET_SALARY_GRADE_REQUEST="GET_SALARY_GRADE_REQUEST"
export const SALARY_GRADE_RESPONSE="SALARY_GRADE_RESPONSE"
export const GET_CATEGORY_LIST_REQUEST="GET_CATEGORY_LIST_REQUEST"
export const CATEGORY_LIST_RESPONSE="CATEGORY_LIST_RESPONSE"
export const SUBMIT_ADD_POSITION_REQUEST="SUBMIT_ADD_POSITION_REQUEST"
export const ACTIVE_AGENTS_RESPONSE="ACTIVE_AGENTS_RESPONSE"
export const SUBMIT_ADD_DEPARTMENT_REQUEST="SUBMIT_ADD_DEPARTMENT_REQUEST"
export const ORGANIZATION_DATA_SAVED = "ORGANIZATION_DATA_SAVED"
export const CLICKED_NODE_DATA="CLICKED_NODE_DATA"
export const GET_DEPARTMENT_DATA_REQUEST="GET_DEPARTMENT_DATA_REQUEST"
export const EDIT_DEPARTMENT_REQUEST="EDIT_DEPARTMENT_REQUEST"
export const DELETE_DEPARTMENT_REQUEST="DELETE_DEPARTMENT_REQUEST"
export const ORGANIZATION_DELETED="ORGANIZATION_DELETED"
export const REQUEST_POSITIONS_CHART = "REQUEST_POSITIONS_CHART";
export const RECIEVE_POSITIONS_CHART = "RECIEVE_POSITIONS_CHART";
export const GET_POSITION_DATA_REQUEST="GET_POSITION_DATA_REQUEST"
export const EDIT_POSITION_REQUEST="EDIT_POSITION_REQUEST"
export const DELETE_POSITION_REQUEST="DELETE_POSITION_REQUEST"