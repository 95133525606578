export default {
  ar: {
    langBtn: "English",
    English: "الانجليزيه",
    Arabic: "العربيه",
    chooseLanguage: "اختر اللغه",
    signIn: "تسجيل الدخول",
    signUp: "سجل مركزك",
    userName: "اسم المشترك",
    email: "البريد الاكترونى",
    startChat: "ابدأ الشات",
    chatMessage: "قم باضافة رسالتك هنا",
    total: "الاجمالي",
    jobPortal: "بوابة الوظائف",
    contact_us_title: "تواصل معنا",
    editEmail: "تعديل نص البريد الإلكتروني",
    addAttachmentToList: "حفظ المستندات الجديدة إلى القائمة",
    sameTemplate: "نعم، احفظ بنفس القالب",
    deleteTemplate: "حذف القالب",
    deleteTemplateApprove: "هل أنت متأكد أنك ترغب في حذف هذا القالب ؟",
    contact_us_content:
      "لمزيد من المعلومات, املأ البيانات التالية و سنقوم بالتواصل معك",
    all: "الكل",
    linkCopied: "تم نسخ الرابط",
    form_controls: {
      first_name: "الاسم الاول",
      last_name: "اسم العائلة",
      email: "البريد الالكترونى",
      company_name_ar: "اسم المركز بالعربية",
      company_name_en: "اسم المركز بالانجليزية",
      vat_num: "الرقم الضريبي",
      websiteLink: "رابط موقع المركز",
      domain: "النطاق",
      password: "كلمة المرور",
      confirm_password: "تاكيد كلمة المرور",
      phone: "رقم الجوال",
      current_password: "كلمة المرور الحالية",
      job_role: "الدور الوظيفى",
      company_size: "حجم المركز",
      company_type: "نوع المركز",
      comment: "نقاط تود مناقشتها",
      requestDemo: "طلب عرض توضيحي",
      recuitBefore: "هل سبق واستخدمت نظام توظيف رقمي؟",
      yes: "نعم",
      no: "لا",
      systemName: "ما هو النظام؟",
    },
    verify: {
      title:
        "قم بتأكيد حسابك لاتخاذ هذا الإجراء والحصول على حق الوصول الكامل إلى اتش ار كوم",
      content: "لم أتلق بريدًا إلكترونيًا؟",
      action: "إعادة إرسال",
    },
    candidateProfileSetup: {
      of: "من",
      autoPublish: "النشر أوتماتيكيًا",
      simpleProfile: "ملف تعريف بسيط",
      complicatedProfile: "الملف التفصيلي",
      resetColor: "استخدام الألوان الإفتراضية",
      resetLogo: "استخدام الصورة الإفتراضية",
      uploadCVMode: " رفع السيرة الذاتية",
      appliedBy: "تم التقديم برفع السيرة الذاتية",
    },
    approvalStage: {
      "Approval stage": "مرحلة الموافقة",
      "Job details": "تفاصيل الوظيفة",
      "Job requisition": "إعدادات متطلبات الوظيفة",
      Summary: "الموجز",
    },
    rejectionReason: {
      nameEn: "السبب بالإنجليزية",
      nameAr: "السبب بالعربية",
      creationDate: "تاريخ الإنشاء",
      addRejectionReason: "إضافة سبب",
      editRejectionReason: "تعديل سبب الرفض",
      creatorName: "اضيف بواسطة",
      deleteReasonContent: "هل ترغب في حذف هذا السبب؟",
      deleteReasonHeader: "حذف سبب الرفض",
    },
    generalSettings: {
      general: "الصفات العامة",
      contractTemplate: "نموذج عقد",
      generalSettings: "الاعدادات العامة",
      CandidateProfileSetup: "إعداد ملف المتقدم",
      sendContract: "إرسال عقد",
      uploadLogoTitle: "تحميل صورة *",
      uploadLogoAction: "تحميل صورة",
      company: "المركز  ",
      agent: "المستخدمين",
      jobRole: "الأدوار الوظيفية",
      recruitment: "إعدادات التوظيف",
      jobTitle: "المسميات الوظيفية",
      salaryGrade: "الدرجة الوظيفية",
      jobRequesition: "اعدادات متطلبات الوظيفة",
      advertisement: "اعدادات الإعلان الوظيفي",
      candidatePortalSetup: "إعدادات النظام",
      pipelineSetupTitle: "إعدادات المراحل الوظيفية",
      subscriptionSettings: "إعدادات الاشتراك",
      subscriptionPlans: "خطط الاشتراكات",
      savedCards: "البطاقات المحفوظة",
      addPrimaryColor: "اللون الأساسي",
      addSecondaryColor: "اللون الثاني",
      offerTemplate: "نموذج العرض الوظيفي",
      integrations: "حلول التكامل",
      library: "النماذج",
      emailTemplates: "نماذج البريد الإلكتروني",
      createEmailTemplate: "إضافة نموذج",
      editEmailTemplate: "تعديل النموذج",
      rejectionReasons: "أسباب الرفض",
      addRejectionReason: "إضافة سبب الرفض",
      editRejectionReason: "تعديل سبب الرفض",
      contractTemplate: "نموذج عقد",
      departments: "الإدارات",
      interviewForm: "نموذج تقيم المقابلة",
    },
    emailTemplates: {
      BacktoDefaultTemplate: "الرجوع للنص الإفتراضي",
      add: "إضافة نموذج",
      templateNameAr: "اسم النموذج بالعربية",
      templateNameEn: "اسم النموذج بالإنجليزية",
      subject: "العنوان",
      body: "المحتوي",
      delete: "هل ترغب في حذف هذا النموذج؟",
      deleteHeader: "حذف النموذج",
      maxSizeImage: "*من فضلك ، في حالة تضمين صورة ان لا تتجاوز 2 ميغا بايت",
      preOfferDocs: "ملفات مسبقة للعرض الوظيفي",
      interviewEvaluationForm: "نموذج تقيم المقابلة",
    },
    errors: {
      failedToFetch: "عذرا، لم بتم التحميل بنجاح, رجاء الإعادة مرة أخرى !",
      required: "هذا الحقل مطلوب",
      requiredFile: "مطلوب رفع ملف",
      validPhone: "رقم الجوال غير صحيح",
      inactive: "التكامل غير مفعل بالفعل",
      validEmail: "بريد الكترونى غير صحيح",
      validDomain:
        "مسموح للغة الإنجليزية فقط ، ولا يُسمح بالتباعد والحروف الخاصة",
      validPassoword_title_tip: ":يجب أن تتبع كلمة المرور القوية هذه القواعد",
      validPassord_min_tip: "على الأقل 8 أحرف ",
      validPassword_mix: "مزيج من الأحرف الكبيرة والصغيرة",
      validPassword_letters: "مزيج من الحروف، الأرقام و الرموز الخاصة",
      validURL: "الرجاء إدخال عنوان صالح للموقع (مثال: https://hrcom.io)",
      validPassword:
        "كلمة مرور خاطئة الاحرف على الاقل 8 و مزيج من الاحرف الكبيرة و الصغيرة و الارقام و الرموز الخاصة",
      validMatching: "كلمة المرور وتأكيد كلمة المرور لا تتطابق",
      imageMaxSize: "يجب ان لا يتعدى حجم الصورة 10 ميجا بايت",
      imagetype: "JPEG, Png فقط مسموح ",
      validNumber: "الرجاء إدخال رقم صحيح",
      validMinDate: "يجب ان يكون التاريخ اكبر من تاريخ اليوم",
      validDate: "الرجاء ادخال تاريخ صحيح",
      minMaxSalary: "الحد الأقصى للأجر يجب ان يكون اكبر من الحد الأدنى",
      medMaxSalary:
        "الحد المتوسط للأجر يجب ان يكون أكبر من الحد الأدنى وأقل من الحد الأقصى",
      salaryValidation: "يقبل فقط الأرقام ويسمح كحد أقصى برقمين عشريين",
      maxFieldsWeightSum: "مجموع الحقول يجب ان يكون اقل من او يساوي 100%",
      minMaxAgeDiff: "الحد الأدنى للعمر يجب أن يكون أقل من الحد الأقصى",
      repeatedLangWithProficiency: "لا يمكنك تكرار اللغة بمستوى الكفاءة",
      repeatedCollegeWithDegree: "لا يمكنك تكرار الكلية و الدرجة العلمية",
      repeatedInstitutegeWithDegree: "لا يمكنك تكرار الجهة التعليمية.",
      repeatedWorkExperience: "لا يمكنك تكرار الخبرة",
      maxWeight: "الأقصى 100",
      invalidNumber: "رقم غير صحيح",
      invalidGPAValue: "يجب ان يكون الرقم مابين 0 - 4",
      closingDaysErr: "على الأقل يوم واحد",
      requiredShort: "مطلوب",
      requiredSectionFieldsWeights: "*يجب إدخال وزن لحقل واحد على الأقل",
      requiredQuestionWeight: "*يجب إدخال وزن لسؤال واحد على الأقل",
      requiredAnswersWeight: "*يجب إدخال وزن لاجابة واحد على الأقل",
      requiredWeight: "الوزن يجب ان يكون اكثر من 0",
      dateIntersection: "يجب ألا تتقاطع إجابات التاريخ مع بعضها البعض",
      timeIntersection: "يجب ألا تتقاطع إجابات الوقت مع بعضها البعض",
      toGreaterThanFrom: "يجب ان تكون 'الى' اكبر 'من' ",
      imageRatio: "يجب ان تكون نسبة الصورة ١:١",
      fileMaxSize: "يجب ان لا يتعدى حجم الملف 2 ميجا بايت",
      FileMaxSize5MB: "يجب ان لا يتعدى حجم الملف 10 ميجا بايت",
      customFileMaxSize: "يجب ان لا يتعدى حجم الملف {maxSize} ميجا بايت",
      pdfOnly: "مسموح فقط ب PDF",
      fileType: "PDF, Word, PNG, JPEG مسموح فقط بملفات",
      evaluationFormFileType:
        " مسموح فقط بملفات PDF, Word, Xls, PNG, JPEG, JPG",
      wizardFileSizeErr: "الحجم المسموح 3 ميجا",
      wizardFileTypeErr: "Excel مسموح فقط بملفات",
      salaryTotalSum: " مفردات المرتب يجب ان لا تتعدى 100%",
      percentageGreaterThanZero: " النسبة يجب ان تزيد عن 0",
      deactivatedCompany:
        " هذه المركز موقوفة , الرجاء الاتصال بنا الايميل info@hrcom.io",
      timeInPast: "لا يمكنك اختيار وقت في الماضي",
      CVfileType: "مسموح فقط PDF, Word",
      endTimeErr: "ميعاد بدء المقابلة يجب ان يسبق ميعاد الانتهاء",
      selectCandidate: "من  فضلك اختر المتقدم أولا",
      selectMultiCandidates: "من فضلك اختر متقدم واحد",
      selectOnePreoffer: "الرجاء اختيار ملف واحد على الأقل",
      deleteDefaultErr:
        "لا يمكن حذف البطاقة الإفتراضية .. من فضلك قم بتفعيل بطاقة أخرى أولًا",
      maxAgentsErr: "لقد وصلت للحد الأقصى من الوكلاء في الخطة الخاصة بكم",
      maxJobsErr:
        "لقد وصلت للحد الأقصى من الوظائف المفتوحة، اشترك في باقة أعلى لإدارة عدد أكبر من الوظائف",
      maxCandidatesErr: "لقد وصلت للحد الأقصى في خطتكم",
      maxVideosDuration: "مدة إجابات جميع الأسئلة يجب أن لا تتجاوز ال 5 دقائق",
      uniqueStageActionErr: "لا توجد إمكانية لإختيار هذا الإجراء أكثر من مرة",
      pastDate: "لا يمكنك اختيار تاريخ في الماضي",
      maxLength100: "الحد الأقصى 100",
      maxLength1000: "الحد الأقصى 1000",

      maxLength30: "الحد الأقصى 30",
      maxLength20: "أقصى طول هو 20",
      numbersOnly: "لا يقبل رموز ",
      maxLength150: "1أقصى طول هو 50",
      maxLength200: "الحد الأقصى 200",
      jobLimitationRemaining: "لا يزال بإمكانك نشر # وظيفة أخرى هذا الشهر",
      oneFieldRequired: "الرجاء إدخال قيمة واحده على الأقل",
    },
    Competencies: {
      AddCompetenciesCategory: "إضافة فئه الجدارات",
      AddCompetenciesItem: "إضافة جداره",
      SaveandNew: "حفظ و اضافة",
      item: "جداره",
      remove: "حذف",
      nameValidation: "الاسم يجب ان لا يكون فارغا",
      AtleastOne: "لابد من إظهار مستوي واحد علي الاقل",
      LevelsSection: "المستويات",
    },
    approvalSetup: {
      approvals: "الاعتمادات الوظيفية",
      noApprovals: "بدون اعتماد وظيفي",
      actionLabel: "اختر إجراء",
      toApprovedBy: "ليوافق عليه",
      addNewApproval: "اضف موافقات جديدة",
      approvalType: "نوع الموافقة",
      jobTitle: "إضافة مسمى وظيفي",
      jobRole: "إضافة دور وظيفي",
      note: "يرجى ملاحظة أنه سيتم إرسال الطلب للموافقة عليه بهذا الترتيب",
      errors: {
        stage_manpower: "مرحلة الموافقة مطلوبة",
        approval_type: "نوع الموافقة مطلوب",
        salary_grades: "سلم الراتب مطلوب",
        salary_ranges: "نطاق الراتب مطلوب",
        approved_by: "الاعتمادات الوظيفية مطلوبة",
        atLeastOneAction: "قم بمليء الاعتمادات الوظيفية بشكل صحيح",
      },
    },
    shared: {
      enable: "تفعيل",
      disable: "تعطيل",
      updateCompanyIndustry: "الرجاء تعديل مجال مركزك",
      send_again: "ارسل مرة اخرى",
      phone: "رقم الجوال",
      address: "العنوان",
      submit: "ارسال",
      message: "الرسالة",
      message_placeholder: "اكتب رسالتك هنا",
      register: "تسجيل",
      registeryourCompany: "سجل مركزك",
      fill_all_form: "*الرجاء ملئ جميع الحقول",
      success_register:
        " تم إرسال بريد إلكتروني للتأكيد ، يرجى التحقق من بريدك الإلكتروني.  إذا لم تتلق رسالة التأكيد الإلكترونية في غضون دقيقة واحدة ، فيرجى الضغط على إرسال مرة أخرى",
      login: "تسجيل الدخول",
      forgetPassword: "نسيت كلمة المرور ؟",
      forgotYourPassword: "نسيت كلمة المرور",
      resetBtn: "استعادة كلمة المرور",
      clear: "مسح ",
      resetTempBtn: "استعادة النموذج الإفتراضي",
      newPassword: "كلمة مرور جديدة",
      confirmNewPassword: "تأكيد كلمة مرور",
      resetYourPass: "استعادة كلمة المرور",
      company_details: "بيانات المركز",
      edit_company_details: "تعديل بيانات المركز",
      addAgentPassword: "اضف كلمة المرور",
      notFoundRecords: "لا توجد بيانات",
      firstViewJobs: "انقر على الزر أدناه لتقوم بإضافة وظيفة جديدة",
      firstViewTalentPool:
        "انقر على الزر أدناه لبدء بناء قاعدة متقدمين قوية يمكنك التواصل معهم بسهولة",

      changePassword: "تغيير كلمة المرور",
      logout: "الخروج",
      copied: "تم النسخ بنجاح",
      and: "و",
      am: "ص",
      pm: "م",
      rate: "التقييم",
      comment: "الملاحظات",
      outOf100: "من أصل 100%",
      label: "",
      notFoundCards: "لا يوجد بطاقات مضافة",
      notFoundPage: "عذرًا ، لم نتمكن من العثور على هذه الصفحة",
      outOf: "من",
      compeleteProfileData: "الرجاء تعديل بيانات مركزك",
      noDataFound: "لا يوجد بيانات",
    },
    buttons: {
      share: "مشاركة",
      add: "اضف",
      remove: "Remove",
      evaluation: "تقييم",
      downloadFiles: "تحميل الملفات",
      skip: "تخطي",
      saveAndSkip: "تخطي وحفظ",
      next: "التالي",
      loading: "تحميل....",
      rejectedOffer: "عذراً هذا العرض الوظيفي غير متاح حالياً",
      cancel: "الغاء",
      submit: "ارسال",
      finish: "انهاء",
      form: "نموذج",
      yes: "نعم",
      no: "لا",
      confirm: "تأكيد",
      createJob: "إضافة وظيفة جديدة",
      edit: "تعديل",
      delete: "مسح",
      addNewBranch: "اضافة فرع جديد",
      uploadImage: "رفع صورة",
      save: "حفظ",
      save_new: "حفظ و إضافة",
      close: "اغلاق",
      showMore: "عرض المزيد",
      saveGoNext: "حفظ و إكمال",
      post: "نشر",
      copyUrl: "إنسخ الرابط",
      done: "تم",
      copyFromTemplate: "أنسخ من قالب",
      view: "عرض",
      useACopy: "نسخ",
      uploadFile: "رفع ملف",
      download: "تحميل",
      uploadExcel: "رفع ملف",
      downloadSample: "تحميل نموذج",
      startInterview: "بدء المقابلة",
      finishInterview: "انهاء المقابلة",
      leaveInterview: "مغادرة المقابلة",
      evaluateInterview: "تقييم",
      attachFile: "ارفق ملف",
      reupload: "ارفق PDF",
      attachPDF: "ارفق PDF",
      pdf: "PDF",
      page: "الصفحة",
      approve: "قبول",
      disApprove: "رفض",
      next: "التالي",
      prev: "السابق",
      finish: "إنهاء",
      sendForApproval: "ارسال للموافقة",
      send: "ارسل",
      proceed: "استمرار",
      goBack: "الرجوع",
      apply: "استخدام",
      print: "طباعة",
      applyDefaultTemplate: "استخدام النموذج الإفتراضي",
      addField: "إضافة حقل",
      reopen: "إعادة النشر",
      signApprove: "توقيع و اعتماد عرض وظيفي",
      signContractApprove: "توقيع و اعتماد عقد وظيفي",
      reset: "مسح",
      duplicate: "تكرار الوظيفة",
      search: "بحث",
      resetSearch: "مسح الكل",
      filter: "تصفية",
      applyFilter: "طبق",
      requestDemo: "طلب عرض توضيحيي",
      requestFreeDemo: "طلب عرض توضيحي مجاني",
      saveAddArabicTemplate: "حفظ و اضافة اللغة العربية",
      saveAddEnglishTemplate: "حفظ و اضافة اللغة الانجليزية",
    },
    branches: {
      addNewBranch: "اضف فرع جديد",
      editBranch: "تعديل بيانات الفرع",
      branch_name: "اسم الفرع",
      address: "العنوان",
      country: "الدولة",
      city: "المدينة",
      form_controls: {
        name_ar: "اسم الفرع بالعربية*",
        name_en: "اسم الفرع بالانجليزية*",
        country: "الدولة*",
        city: "المدينة*",
        address_ar: "العنوان بالعربية*",
        address_en: "العنوان بالانجليزية*",
      },
    },
    confirmation: {
      cancel: "هل ترغب فى الغاء طلبك؟",
      cancelHeader: "الغاء الطلب",
      deleteBranchHeader: "حذف الفرع",
      deleteBranchContent: "هل ترغب فى حذف هذا الفرع؟",
    },
    jobRoles: {
      SaveUpdate: "حفظ وتعديل",
      job_role_ar: "الدور الوظيفى بالعربية",
      job_role_en: "الدور الوظيفى بالانجليزية",
      jobRolesRequired: "الدور الوظيفي مطلوب",
      assigedAgents: "عدد المستخدمين",
      permissions: "صلاحيات",
      addNewJobRole: "اضف دور وظيفى جديد",
      assignedJobRole: "العملاء المسجلين فى",
      editJobRole: "تعديل الدور الوظيفى",
      atLeastOnPermission:
        "يجب ان يحتوى الدور الوظيفى على صلاحية واحدة على الأقل",
      job_role: "الوظيفة",
      deleteJobRoleTitle: "حذف وظيفة",
      deleteJobRoleContent: "هل ترغب فى حذف هذه الوظيفة؟",
    },
    agents: {
      addNewAgent: "إضافة مستخدم جديد",
      editAgent: "تعديل بيانات المستخدم",
      agentName: "اسم المستخدم",
      agentEmail: "البريد الالكتروني",
      agentRoles: "الوظيفة",
      status: "الحالة",
      deleteAgent: "حذف عميل",
      deleteAgentContent:
        "هل ترغب فى حذف هذا العميل، علما بأن لايمكنه الوصول لحسابه؟",
      ActivateAgent: "هل ترغب فى تفعيل هذا العميل؟",
      DeactivateAgent: "هل ترغب فى ايقاف هذا العميل؟",
      ActivateTitle: "تفعيل عميل",
      DeactivateTitle: "ايقاف عميل",
      activated: "مفعل",
      deactivated: "موقوف",
      agentProfile: "الملف الشخصى",
      confirmed: "مثبت",
      notConfirmed: "غير مثبت",
      verifyModalContent:
        "هل تريد بالتأكيد إرسال بريد إلكتروني للتحقق إلى هذا المستخدم؟",
      verifyModalHeader: "إرسال بريد إلكتروني للتحقق",
      department: "القسم",
      jobTitle: "المسمى الوظيفي",
      activeAgents: "المدير المباشر",
    },
    sideMenu: {
      interviewEvaluationForm: "نموذج تقيم المقابلة",
      Competences: "إعدادات الجدارات",
      activejobs: "الوظائف النشطة",
      Archived: "الأرشيف",
      ManageCandidateProfile: "إدارة ملف المتقدم",
      ManageJobDetails: "إدارة تفاصيل الوظيفة",
      recruitmentPlan: "خطة التوظيف",
      Jobopening: "الوظائف المتاحه",
      OrganizationalStructure: "الهيكل التنظيمي",
      positionChart: "التدرج الوظيفي",
      Organization: "الإدارة",
      ManpowerRequests: "طلبات التوظيف",
      Dashboard: "الصفحة الرئيسية",
      TalentPool: "قاعدة المتقدمين",
      Settings: "الاعدادات",
      approvalSetup: "الاعتمادات الوظيفية",
      ApprovalSetup: " تحديد الاعتمادات الوظيفية",
      createNewRequest: "إضافة طلب جديد",
      viewAllrequests: "مراجعة جميع الطلبات",
      Requests: "طلبات",
      candidateProfile: "عن المتقدم",
      profile: "الملف الشخصى",
      applicantProfile: "عن المتقدم",
      RequestDetails: "تفاصيل الطلب",
      CandidatePortalSetup: "إعدادات النظام",
      interview: "المقابلات",
      myInterviews: "المقابلات",
      jobOpenings: "الوظائف المتاحه",
      manageCandidates: "إدارة المتقدمين",
      viewjobSummary: "تفاصيل الوظيفة",
      advertisementPreview: "معاينة الإعلان",
      changePassword: "تغيير كلمة المرور",
      advertisementSetup: "اعدادات الإعلان الوظيفي",
      viewAllRequests: "مراجعة جميع الطلبات",
      manpowerRequests: "طلبات التوظيف",
      jobTitles: "المسميات الوظيفية",
      jobTitle: "المسميات الوظيفية",
      jobRequisitionSetup: "إعدادات متطلبات الوظيفة",
      salaryGrade: "سلم الرواتب",
      salaryGrades: "سلم الرواتب",
      agents: "المستخدمين",
      agent: "المستخدمين",
      jobRoles: "الأدوار الوظيفية",
      jobRole: "الدور الوظيفى",
      branch: "المركز",
      companyEdit: "المركز",
      companySetup: "المركز",
      setup: "الاعدادات",
      interviewQuestions: "الاسئلة",
      interviewsSetup: "اعداد المقابلات",
      pipelineFlows: "إعدادات المراحل الوظيفية",
      addPipelineFlow: "إعدادات المراحل الوظيفية",
      cardsSetup: "إعدادات الاشتراك",
      subscriptionPlans: "خطط الاشتراكات",
      offerTemplate: "نموذج العرض الوظيفي",
      candidatesRequests: "طلبات المتقدمين",
      agentsRequests: "طلبات المستخدمين",
      invoices: "عرض الفواتير",
      integrations: "حلول التكامل",
      emailTemplates: "نماذج البريد الإلكتروني",
      createEmailTemplate: "اضافة نموذج",
      editEmailTemplate: "تعديل النموذج",
      report: "التقارير",
      rejectionReasons: "أسباب الرفض",
      addRejectionReason: "إضافة سبب الرفض",
      editRejectionReason: "تعديل سبب الرفض",
      contractTemplate: "نموذج عقد",
    },
    salaryGrade: {
      salaray_grade_en: "الدرجة الوظيفية بالانجليزية",
      salaray_grade_ar: "الدرجة الوظيفية بالعربية",
      min: "الادنى",
      max: "الاقصى",
      med: "المتوسط",
      currency: "العملة",
      addNewSalaryGrade: "اضف درجة راتب جديدة",
      editSalaryGrade: "تعديل درجة راتب ",
      grade_name_ar: "الفئة بالعربية",
      grade_name_en: "الفئة بالانجليزية",
      min_salary: "أدنى",
      max_salary: "أقصى",
      med_salary: "متوسط",
      published_date: "تاريخ النشر",
    },
    recruitmentPlan: {
      title: "قم بتحميل خطة التوظيف",
      uploadBtn: "رفع ملف الوظائف",
      tooltipExcel: "قم بتحميل نموذج الاكسل أولاً",
      tooltipRemove: "لا تقم بإزالة أو إضافة أعمدة إلى الملف",
    },
    jobTitles: {
      addNewGobTitleBtn: "إضافة وظيفة جديدة",
      jobTitleAr: "الوظيفة بالعربية",
      jobTitleEn: "الوظيفة بالانجليزية",
      salaryGrade: "الأجر",
      createNewJobTitleHeader: "أضف وظيفة جديدة",
      editJobTitleHeader: "تعديل الوظيفة",
      category: "تصنيف",
      executiveDirector: "المدير التنفيذي للخدمات المشتركة",
    },
    jobRequisitionSetup: {
      Editable: "قابل للتعديل",
      jobRequisitionHeader: "إعدادات متطلبات الوظيفة",
      companyBriefAR: "كلمة عن المركز بالعربية",
      companyBriefEN: "كلمة عن المركز بالانجليزية",
      industryName: "قطاع المركز",
      show: "عرض",
      hide: "اخفاء",
      Mandatory: "إلزامي",
      withRate: "طلب مع معدل",
      noRate: "طلب بدون معدل",
      noRateDescAr: "الطلب بالعربية",
      noRateDescEn: "الطلب بالانجليزية",
      details: "طلب محدد",
      noDetails: "طلب مختصر",
      requisitionType: "اختر نوع الطلب",
      jobDescription: "الوصف الوظيفي",
      companyBrief: "كلمة عن المركز",
    },
    jobDetailsStep: {
      reference_number: "الرقم المرجعي",
      jobLocation: "موقع الوظيفة",
      job_category: "حالة الوظيفة",
      replaced_employee: "الموظف المستبدل",
      jobDetailsHeader: "تفاصيل الوظيفة",
      jobTitle: "اسم الوظيفة",
      salaryGrade: "الدرجة الوظيفية",
      basicSalary: "راتب اساسي",
      openings: "عدد الأماكن الشاغرة",
      companyBranch: "فرع المركز",
      joiningDate: "تاريخ غلق الوظيفة",
      jobType: "نوع الوظيفة",
      externalJob: "خارجية",
      housing: "بدل السكن",
      transportation: "بدل النقل",
      name: "الاسم",
      addJobTitle: "إضافة وظيفة جديدة",
      addSalary: "إضافة راتب",
      removeSalary: "إزالة الراتب",
      department: "القسم",
      value: "القيمة",
      mobileAllowance: "بدل جوال",
      percentage: "نسبة",
    },
    adsSetup: {
      adsHeader: "اعدادات الإعلان الوظيفي",
      adsTitle: "العنوان",
      adsDescription: "الوصف",
      adsImage: "الصورة",
      addField: "إضافة حقل",
    },
    manageJobRequisition: {
      sameTemplate: "نعم، احفظ بنفس القالب",
      manageJobRequisitionHeader: "متطلبات الوظيفة",
      lessThanOrEqual: "يجب ان يكون اقل من او يساوي ١٠٠",
      showInAdvertisement: "يظهر فى الإعلان",
      preferred: "مفضل",
      disqualifier: "غير مؤهل",
      equals: "يساوى",
      lessThan: "أصغر من",
      greaterThan: "أكبر من",
      between: "بين",
      min: "الأدنى",
      max: "الأقصى",
      age: "العمر",
      proficiencyLevel: "مستوى الكفاءة",
      jobDescriptionAr: "الوصف الوظيفي بالعربية",
      jobDescriptionEn: "الوصف الوظيفي بالانجليزية",
      closingData: "تاريخ الاغلاق",
      workIndustry: "مجال العمل",
      days: "أيام",
      yearsOfExp: "سنوات الخبرة",
      yearsLabel: "السنوات",
      fieldsLabel: "المجال",
      inTheField: "في المجال",
      collegeLabel: "الكلية",
      InstituteSchool: "الجهة التعليمية",
      degreeLabel: "الدرجة العلمية",
      GPALabel: "GPA",
      Competencies: "الكفاءات ",
      skillsAr: "المهارات بالعربية",
      skillsEn: "المهارات بالانجليزية",
      saveTemplateHeader: "هل تريد حفظ هذا الطلب كقالب؟",
      templateNameAr: "اسم القالب بالعربية",
      templateNameEn: "اسم القالب بالانجليزية",
      modalYesBtn: "نعم, احفظ كقالب",
      modalNoBtn: "لا, احفظ وأكمل",
      ageLevelLabel: "المستوى",
      copyTemplateConfirmModalContent:
        "هل ترغب فى تحميل القالب المحفوظ للمتطلبات؟ مع العلم أنه سيتم حذف بياناتك الحالية",
      fileLabel: "ملف",
      descriptiveFileLabel: "ملف وصفى",
      requisitionAr: "الوصف الوظيفى بالعربية",
      requisitionEn: "الوصف الوظيفى بالانجليزية",
      requisitionRate: "طلب مع معدل",
      requisitionNoRate: "طلب بدون معدل",
      contractType: "نوع عرض وظيفي",
      fieldsOfStudy: "التخصص",
      jobNotPostableToDrJob:
        "لن يسمح لك هذا النوع من الطلبات بمشاركة الوظيفة على Dr.Job",
    },
    manageScreeningQuestions: {
      section_title_ar: "اسم القسم بالعربية",
      section_title_en: "اسم القسم بالانجليزية",
      create_new_question: "إضافة سؤال جديد",
      copy_question_from_library: "نسخة من مكتبة الأسئلة",
      weight: "الوزن%",
      question_type: "النوع",
      preferred: "مفضل",
      disqualifier: "غير مؤهل",
      question_ar: "السؤال بالعربية",
      question_en: "السؤال بالانجليزية",
      answer_ar: "الاجابة بالعربية",
      answer_en: "الاجابة بالانجليزية",
      section: "القسم",
      question: "السؤال",
      addNewSection: "اضف قسم جديد",
      operation: "العملية",
      type: "النوع",
      date_from: "التاريخ من",
      date_to: "التاريخ الى",
      date: "التاريخ",
      time: "الوقت",
      time_from: "الوقت من",
      time_to: "الوقت الى",
      disqualifierSelectTitle: "اختر الاجابة الصحيحة",
      add: "اضف",
      screeningTitle: "الاسئلة الوظيفية",
      saveTemplateHeader: "هل تريد حفظ هذا التحرى كقالب؟",
      copyTemplateConfirmModalContent:
        "هل ترغب فى تحميل القالب المحفوظ للتحرى؟ مع العلم أنه سيتم حذف بياناتك الحالية",
      skipScreening: "تخطى التحرى",
    },
    report: {
      Offers: "العروض",
      totalOffers: " مجموع العروض",
      totalContracts: "مجموع العقود",

      Accepted: "مقبول",
      Rejected: "مرفوض",
      Negotiation: "تفاوض",
      "Waiting for candidate": "في انتظار المتقدم",
      Pending: "قيد الإنتظار",
      Interviews: "المقابلات",
      website: "بواسطة الموقع",
      manual: "مضاف يدوياً",
      totalInterviews: "مجموع المقابلات",
      "Face-to-face": "وجهًا لوجه",
      Phone: "هاتف",
      Video: "فيديو",
      addedByWebsite: "متقدم عن طريق الموقع",
      addedManual: "مضاف يدوياً",
      totalCandidates: "مجموع المتقدمين",
      totalJobOpening: "مجموع الوظائف المتاحة",
      jobOpening: "الوظائف المتاحة",
      "In progress": "قيد التنفيذ",
      Opened: "مفتوحة",
      hold: "معلق",
      closed: "مغلقة",
      Agents: "المستخدمين",
      "Total of agents": "مجموع المستخدمين",
      jobStage: "المراحل الوظيفية",
      averageAgeOfJobs: "متوسط عمر الوظائف",
      averageClosedJobsAge: "متوسط عمر الوظائف المغلقة",
      averageOpenedJobsAge: "متوسط عمر الوظائف المفتوحة",
      days: " يوم ",
      Activated: "مفعل",
      Deactivated: "غير مفعل",
    },
    viewAllRequests: {
      jobSummary: "موجز الوظيفة",
      Archive: "أرشيف",
      Retrieve: "استعادة",
      requestId: "رقم الوظيفة",
      jobTitles: "المسميات الوظيفية",
      vacanciesNumber: "عدد الشواغر",
      joiningDate: "تاريخ غلق الوظيفة",
      disAprpoveDate: "تاريخ الرفض",
      aprpoveDate: "تاريخ الموافقة",
      state: "حالة الطلب",
      creationDate: "تاريخ الموافقة",
      jobRequistion: "متطلبات الوظيفة",
      summary: "موجز",
      screening: "الأسئلة الوظيفية",
      tabsInprogress: "قيد التنفيذ",
      tabsPublished: "منشور",
      tabsClosed: "مغلق",
      tabsHolded: "معلق",
      by: "بواسطة",
      deleteRequestModalHeader: "حذف الطلب",
      deleteRequestModalContent: "هل ترغب فى حذف هذا الطلب؟",
      publishingDate: "تاريخ النشر",
      closingDate: "تاريخ الانتهاء",
      branch: "الفرع",
      country: "البلد",
      city: "المدينة",
      status: "الحالة",
      applicants: "مجموع المتقدمين",
      newApplicants: "المتقدمين الجدد",
      shortlisted: "القائمة المختصرة",
      interviews: "المقابلات (مكتملة / مجدولة)",
      offers: "العروض الوظيفية",
      accepted: "المقبولين",
      rejected: "المرفوضين",
      manageCandidates: "إدارة المتقدمين",
      changeClosingDate: "تغيير ميعاد الغلق",
      outOf: "من أصل",
      waitingForApproval: "بانتظار الموافقة",
      approvedRequest: "تم اعتماد",
      disapprovedRequest: "مرفوض",
      totalConsumedMinutes: "مجموع الدقائق المستهلكة",
      closeJobModalContent: "هل أنت متأكد أنك ترغب في غلق هذه الوظيفة؟",
      closeJobModalHeader: "غلق الوظيفة",
      holdJobRequest: "تعليق",
      Filled: "ممتليء",
      partialFilled: "ممتلئ جزئيًا",
      fillDate: "تاريخ المليء",
    },
    requestSummary: {
      summaryHeader: "الموجز",
      advertisementPreviewBtn: "معاينة الإعلان",
      publishPostConfirmModalHeader: "نشر الوظيفة",
      publishPostConfirmModalContent: "هل ترغب فى نشر هذه الوظيفه؟",
      companyBreifHeader: "نبذة عن المركز",
      jobSpecsHeader: " خصائص الوظيفة",
      residencyHeader: "بلد الاقامة",
      ResidencyCity: "مدينة الإقامة",
      nationalityHeader: "الجنسية",
      ageHeader: "العمر",
      genderHeader: "الجنس",
      MaritalStatusHeader: "الحالة الاجتماعية",
      languageHeader: "اللغة",
      careerLevelHeader: "المستوى الوظيفى",
      jobDescribtionHeader: "الوصف الوظيفي",
      workExperienceHeader: "الخبرة",
      educationHeader: "المؤهل التعليمى",
      competenciesHeader: "الكفاءات",
      skillsHeader: "المهارات",
      weightHeader: "الوزن",
      answer: "الاجابة",
      noData: "لا يوجد بيانات",
      fileName: "اسم الملف",
    },
    advertisementPreview: {
      advertisementPreviewHeader: "معاينة الإعلان",
      closeAdvertismentModalHeader: "إغلاق الإعلان",
      closeAdvertismentModalContent:
        "هل أنت متأكد من أنك تريد إغلاق الإعلان؟ مع ملاحظة أنه سيتم حذف تعديلاتك",
    },
    tabsHeader: {
      applicants: "المتقدمين",
      shortlisted: "القائمة المختصرة",
      interview: "المقابلات",
      offers: "العروض",
      rejected: "المرفوضين",
      accepted: "المقبولين",
      candidateInfo: "بيانات المتقدم",
      cv: "السيرة الذاتية",
      jobDetails: "بيانات الوظيفة",
    },
    manageCandidates: {
      ReferenceNo: "الرقم المرجعي",
      sendRegressionLetter: "ارسال ايميل الرفض",
      ConnectVia: "التواصل عبر",
      age: "العمر",
      yearsOfExperience: "سنوات الخبرة",
      name: "الاسم",
      email: "البريد الالكتروني",
      phone: "الهاتف",
      gender: "الجنس",
      appliedOn: "الوظائف المقدم عليها",
      reject: "رفض",
      offer: "عرض",
      notes: "ملاحظات",
      scheduleInterview: "ترتيب مقابلة",
      informApplicant: "ابلاغ المتقدم",
      male: "ذكر",
      female: "أنثي",
      moveToShortList: "القائمة المختصرة",
      interviewDate: " تاريخ المقابلة ",
      interviewStartTime: "بدء",
      interviewEndTime: "انهاء",
      interviewerName: "اسم المقابل",
      organizerName: "منظم المقابلة",
      schedule: "احجز المقابلة",
      candidateName: "اسم المتقدم",
      status: "الحالة",
      rate: "تصنيف",
      onBoarding: "تهيئة الموظفين الجدد",

      reScheduleInterview: "اعادة الجدولة",
      internalEmail: "البريد الالكتروني للموظف",
      pending: "قيد الانتظار",
      interviewed: "منتهى",
      rejectBy: "رفض بواسطة",
      rejectOn: "وقت الرفض",
      rejectState: "حالة الرفض",
      viewReason: "سبب الرفض",
      rejectionReason: "سبب الرفض",
      type: "نوع المقابلة",
      sendRegressionLetter: "ارسال ايميل الرفض",

      sendRegressionLetterNow: "الاّن",
      sendRegressionLetterAfter5Days: "بعد 5 ايام ",
      sendRegressionLetterAfter10Days: "بعد 10 ايام ",
      age: "العمر",
      yearsOfExperience: "سنوات الخبرة",
      note: "ملاحظة",
      name: "الاسم",
      email: "البريد الالكتروني",
      phone: "الهاتف",
      gender: "الجنس",
      appliedOn: "الوظائف المقدم عليها",
      reject: "رفض",
      offer: "عرض",
      notes: "ملاحظات",
      scheduleInterview: "ترتيب مقابلة",
      informApplicant: "ابلاغ المتقدم",
      male: "ذكر",
      female: "أنثي",
      moveToShortList: "القائمة المختصرة",
      interviewDate: " تاريخ المقابلة ",
      interviewStartTime: "بدء",
      interviewEndTime: "انهاء",
      interviewerName: "اسم المقابل",
      schedule: "احجز المقابلة",
      candidateName: "اسم المتقدم",
      status: "الحالة",
      rate: "تصنيف",
      reScheduleInterview: "اعادة الجدولة",
      pending: "قيد الانتظار",
      interviewed: "منتهى",
      rejectBy: "رفض بواسطة",
      rejectOn: "وقت الرفض",
      rejectState: "حالة الرفض",
      viewReason: "سبب الرفض",
      rejectionReason: "سبب الرفض",
      type: "نوع المقابلة",
      sendRegressionLetter: "ارسال ايميل الرفض",
      interviewName: " اسم المقابلة",
      applicant: "المتقدم",
      interviewType: "نوع المقابلة",
      video: "فيديو",
      faceToFace: "وجها لوجه",
      location: "الموقع",
      subject: "العنوان",
      body: "المحتوى",
      editEmailURLS: "الروابط",
      title: "المقدمة",
      viewEmailTemplate: "مراجعة نص البريد الالكترونى",
      interviewLocation: "مكان المقابلة",
      actions: "الإجراءات",
      stages: "المراحل",
      preofferTitle: "اضافة مرفقات",
      addMore: "إضافة مرفق",
      documentNameEn: "اسم الملف بالانجليزية",
      documentNameAr: "اسم الملف بالعربية",
      sendEmail: "ارسال بريد الكترونى",
      inform_candidate: " ارسل ايميل للمتقدم",
      addToAnotherJob: "اضف الى وظيفة",
      sendInformationRequest: "إرسال طلب معلومات",
      selectAnswerType: "اختيار نوع الاجابة",
      textAnswers: "إجابات نصية",
      addQuestion: "أضف سؤالك",
      numberOfLetter: "عدد الحروف",
      letters: "حروف",
      enterQuestion: " أضف سؤالك",
      addAnotherQuestion: "أضف سؤال آخر",
      videoDuration: "مدة الفيديو",
      minutes: "دقائق",
      expirationDate: "تاريخ الانتهاء",
      videoAnswer: "تسجيل فيديو",
      approvalStatus: "حالة الموافقات",
      school: "معهد / مدرسة",
    },
    myInterviews: {
      InterviewResult: "نتيجة المقابله",
      present: "حضور",
      absent: "متغيب",
      reschedule: "إعادة الجدولة",
      prevInterviews: "المقابلات السابقة",
      currentInterviews: "المقابلات الحالية",
      myInterviews: "المقابلات",
      name: "الاسم",
      jobOpening: "الوظائف المتاحه",
      interviewDate: "تاريخ المقابلة",
      interviewTime: "وقت المقابلة",
      startInterview: "بدء المقابلة",
      finishInterview: "انهاء المقابلة",
      interviewName: "اسم المقابلة",
      interviewType: "نوع المقابلة",
      questions: "الاسئلة",
      question: "سؤال",
      noQuestions: "لا يوجد أسئلة.",
      skills: "مهارات",
      knowledge: "معرفة",
      attitude: "سلوك",
      noCVS: "لا يوجد سيرة ذاتية مرفقة",
      jobTitle: "اسم الوظيفة",
      nextStage: "المرحلة القادمة",
      salary: "راتب اساسي",
      Housing: " بدل السكن ",
      Transportation: "بدل النقل",
      noOfOpen: "عدد الأماكن الشاغرة",
      companybranch: "فرع المركز",
      city: "المدينة",
      closeDate: "تاريخ الإغلاق",
      rate: "التقييم",
      Residency: "بلد الإقامة",
      ResidencyCity: "مدينة الإقامة",
      "Current/Last Salary": " المرتب الحالي",
      "Current/Last position": "الوظيفة الحالية",
      "Current/Last company name": "المركز الحالية",
    },
    snackbar: {
      done: "تم بنجاح",
      contactModalSuccess: "شكرًا على اتصالك بنا ، لقد تم إرسال رسالتك بنجاح",
      savedSuccessfuly: "لقد تم حفظ البيانات بنجاح",
      delayClosingDate: "لقد تم التحديث بنجاح",
      moveToInterviewGrid: "لقد تم النقل الى المقابلات بنجاح",
      successMoveShortlisted: "تم نقل المرشح إلى القائمة المختصرة بنجاح",
      rejectedSuccessfully: "لقد تم رفض الطلب بنجاح",
      savedSuccessfulyDrJob: " تم نشر وظيفتك بنجاح على Dr. Job",
    },
    wizard: {
      phoneNumber: "رقم الجوال",
      uploadImageAllowed: "مسموح فقط بملفات JPG, PNG, JPEG",
      superAdminPhoneNumber: "رقم جوال المشرف الرئيسي",
      hello: "مرحبا",
      addManpowerRequest: "طلب وظيفة",
      addAgent: "إضافة مستخدم",
      wizardStartMsg: "!دعنا نساعدك في بناء إعدادات مركزك",
      companyDetails: "تفاصيل المركز",
      Headquarter: "الفرع الرئيسى",
      companyFieldsMsg: "ماهو قطاع مركزك",
      salaryGradesMsg: "ما هي نطاقات الراتب والعملة في مركزك",
      notasksMsg: "ليس لديك مهام اليوم!",
      companyFieldsLabel: "قطاع المركز",
      completeProfile: "معلومات عن مركزك",
      noTasksMsg: "لا يوجد مهام اليوم !",
      addAgents: "اضف العملاء",
      addSalaryGrades: "اضف الرواتب",
      agentsTooltipDownload: "قم بتنزيل نموذج الاكسل أولاً",
      agentsTooltipRemoveColumn: "لا تقم بإزالة أو إضافة أعمدة إلى الملف",
      agentsTooltipFirstLastNameMaxChar:
        "يبلغ الحد الأقصى للاسم الاول و الأخير 50 ​​حرفًا",
      agentsTooltipRepeatEmails: "لا يمكن تكرار البريد الإلكتروني",
      agentsTooltipMandatory: "جميع الأعمدة إلزامية",
      agentsTooltipSalaryNameMaxChar: "يبلغ الحد الأقصى لاسم الراتب 50 ​​حرفًا",
      agentsTooltipMaxMinDiff: "الحد الاقصى يجب ان يزيد عن الحد الادنى",
      agentsTooltipSalaryNameUnique: "لا يمكن تكرار اسم الراتب",
      helpNoteAgents:
        "*يرجى العلم بأنه يجب تحميل النموذج أولا لكى يتسنى لك رفع ملف العملاء",
      helpNoteSalary:
        "*يرجى العلم بأنه يجب تحميل النموذج أولا لكى يتسنى لك رفع ملف الرواتب",
    },
    dashboardContent: {
      jobPost: "وظائف شاغرة",
      createAccount: "إنشاء حساب",
      compeleteProfile: "معلومات عن مركزك",
      settings: "الاعدادات",
      dashboardPageContent:
        "انقر على الزر أدناه للوصول إلى الإعداد الخاص بجميع المزايا",
      opened: "مفتوحة",
      closed: "مغلقة",
      total: "مجموع",
      candidates: "المتقدمين",
      jobOpenings: "الوظائف المتاحه",
      totalJobPosts: "مجموع الوظائف المتاحة",
      openingJobPosts: "الوظائف المتاحه",
      closedJobPosts: "الوظائف المغلقه",
      totalApplicants: "مجموع المتقدمين",
      talentPool: "قاعدة المتقدمين",
      websiteApplicants: "متقدم عن طريق الموقع",
      addedManualy: "مضاف يدوياً",
      suggestedCandidate: "المتقدمين المقترحين من اتش ار كوم",
    },
    Requests: {
      activejobs: "active jobs",
      Archived: "Archived",
      updatedDate: "التاريخ المؤكد للانضمام: ",
      confirmDate: "تاريخ الإنضمام المؤكد",
      Requests: "الاعتمادات",
      requestName: "الطلب",
      creaetdBy: "بواسطة",
      creaetdOn: "تاريخ الانشاء",
      reajectionHaeading: "سبب الرفض",
      requestDetailsHeading: "تفاصيل الطلب",
      oldRequests: "طلبات قديمة",
      newRequests: "طلبات جديدة",
      requestDate: "تاريخ الطلب",
      status: "الحالة",
      showDetails: "تفاصيل الطلب",
      requestDetails: "تفاصيل الطلب",
      originalExpirationDate: "تاريخ الانتهاء الاصلى",
      originalJoiningDate: "تأكيد تاريخ الإنضمام",
      joiningDate: "تاريخ الإنضمام",
      viewOffer: "العرض الوظيفي",
      andCandidateRequestedto: "و طلب المتقدم تغييره ل",
      requestedExpirationDate: "تاريخ الانتهاء الجديد",
      reason: "السبب",
      approval: "القبول",
      Comments: "التعليقات",
      comment: "التعليق",
    },
    applicantProfile: {
      profile: "الملف الشخصى",
      screening: "الاسئلة الوظيفية",
      application: "الوظائف المقدم عليها",
      aaplicationSmallcase: "طلب توظيف",
      answers: "الاجابات",
      candidateAnswer: "إجابة المتقدم",
      manuallyAddedApplicant: "هذا المتقدم تم اضافته من قبل العملاء",
      resume: "السيرة الذاتية",
      downloadResume: "تحميل السيرة الذاتية",
      summary: "الاستطلاع",
      advertQuestions: "الاستطلاع",
      log: "السجل",
      candidateLog: "سجل المتقدم",
      contracts: "العقود الوظيفية",
      Contract: "العقد",
      summaryTab: {
        advertQuestionsTitle: "الاستطلاع",
        currentLastSalary: "المرتب الحالى",
        currency: "العملة",
        currentLastPosition: "الوظيفة الحالية",
        currentLastCompany: "المركز الحالي",
        PreOfferAttachements: "المرفقات",
        moreInfoLog: "",
        unemployee: "باحث عن عمل",
        employed: "موظف",
        employeeStatus: "حالة الوظيفه",
      },
      applicationsTab: {
        job_title: "اسم الوظيفة",
        applied_on: "تاريخ التقديم",
        stage_name: "اسم المرحلة",
        screening: "الاسئلة الوظيفية",
        interviews: "المقابلات",
        branch: "الفرع",
        request_id: "رقم الوظيفة",
      },
      applicatProfileTab: {
        education: "التعليم",
        workExperience: "الخبرات",
        motherTongue: "اللغة الام",
        college: "الكلية",
        fieldsOfStudy: "التخصص",
        InstituteSchool: "الجهة التعليمية.",
        file: "الملف",
        degree: "الدرجة العلمية",
        gpa: "الدرجة /المعدل التراكمي ",
        category: "تصنيف",
        jobTitle: "اسم الوظيفة",
        companyName: "اسم المركز",
        location: "الموقع",
        from: "من",
        to: "إلى",
        description: "الوصف",
        addEducation: "إضافة المؤهل",
        editEducation: "تعديل المؤهل",
        toPresent: "إلى الوقت الحالى",
        addWorkExperience: "إضافة الخبرة",
        editWorkExperience: "تعديل الخبرة",
        present: "الوقت الحالى",
        outOf: "من أصل",
        customJobTitle: "ادخل اسم الوظيفة",
        personalInfoTitle: "معلومات شخصية",
        firstName: "الاسم الاول",
        lastName: "اسم العائلة",
        dateOfBirth: "تاريخ الميلاد",
        residency: "بلد الاقامة",
        Residency: "الاقامة",
        ResidencyCity: "مدينة الإقامة",
        gender: "الجنس",
        maritalStatus: "الحالة الاجتماعية",
        contactDetails: "بيانات الاتصال",
        phoneNumber: "رقم الجوال",
        personalEmail: "البريد الالكترونى",
        address: "العنوان",
        male: "ذكر",
        female: "انثى",
        skillsInfoTitle: "المهارات",
        profLevel: "مستوى الكفاءة",
        engProLevel: "ما هو مستوى إتقانك للغة الإنجليزية؟",
        languages: "اللغات",
        profLevelInEnglish: "مستوى إتقانك للغة الإنجليزية ",
        licenses: "التراخيص والشهادات",
        certificateName: "اسم الشهادة",
        issuingOrganization: "منظمة الإصدار",
        issuedDate: "تاريخ الاصدار",
        expirationDate: "تاريخ الصلاحية",
        noExpirationDate: "لا يوجد تاريخ صلاحية لهذه الشهادة",
      },
      interviewsTab: {
        interviews: "المقابلات",
        interview: "المقابلة",
        interviewName: "اسم المقابلة",
        interviewerName: "اسم المقابل",
        interviewDate: "تاريخ المقابلة",
        interviewTime: "وقت المقابلة",
        interviewType: "نوع المقابلة",
        interviewStatus: "حالة المقابلة",
        interviewRate: "التقييم",
        interviewComments: "الملاحظات",
        pending: "قيد الانتظار",
        completed: "مكتملة",
      },
      moreInformationRequestTab: {
        moreInfoRequest: "طلب معلومات إضافية",
        playVideo: "عرض الفيديو",
        showAnswer: "عرض الاجابة",
        minutes: "دقائق",
        letters: "حروف",
        noVideo: " لا يوجد فيديو بعد",
        noText: "لا توجد اجابة بعد",
      },
      offerTab: {
        offers: "العروض الوظيفية",
        requestname: "اسم الطلب",
        createOn: "تاريخ الإنشاء",
        status: "الحالة",
        file: "الملف",
      },
    },
    addApplicant: {
      addApplicantTitle: "إضافة متقدم",
      firstName: "الاسم الاول",
      lastName: "اسم العائلة",
      email: "البريد الالكترونى",
      phone: "رقم الجوال",
      uploadCV: "رفع السيرة الذاتية",
      importFromExcel: "رفع ملف",
      allowedStructure:
        "البنية المسموح بها للملف المرفوع هو ألاسم الأول, أسم العائلة, البريد الالكترونى ",
      allowedSize: "الحجم المسموح 10 ميجا",
      allowedRecordsNumber: "لا يسمح بإضافة اكثر من 100 متقدم",
      allowedType: "مسموح بإكسل فقط",
      cvDataBank: "قائمة المتقدمين",
      certainJob: "وظيفة",
      addTo: "اضف الى :",
      informApplicants: "ابلاغ المتقدمين",
      sendSms: "قم بإرسال رسالة نصية",
    },
    pipelineSetup: {
      ActionColorInReports: "لون الإجراء في التقارير",
      flow_name: "اسم المسار",
      number_of_stages: "عدد المراحل",
      created_on: "تاريخ الانشاء",
      default_flow: "اختار المسار الاساسى",
      addPipelineFlow: "أضف مسار",
      editPipelineFlow: "تعديل المسار",
      flowNameAr: "اسم المسار بالعربية",
      flowNameEn: "اسم المسار بالإنجليزية",
      action: "الإجراء",
      saveFlow: "حفظ المسار",
      saveStage: "حفظ المرحلة",
      addStage: "اضف مرحلة",
      stageNameAr: "اسم المرحلة بالعربية",
      stageNameEn: "اسم المرحلة بالإنجليزية",
      deleteStageModalHeader: "حذف المرحلة",
      deleteStageModalContent: "هل ترغب في حذف هذه المرحلة ؟",
      stageNameUnique: "اسم المرحلة يجب ان لا يتكرر",
      stageHasApplicants:
        "هناك متقدمين في هذه المرحلة يرجى نقلهم إلى مرحلة أخرى",
      stage: "المرحلة",
      defaultFlowTitle: "المسار ",
      deleteFlow: "حذف المسار",
      deleteFlowModalContent: "هل تريد حذف هذا المسار؟",
      deleteDefaultFlow: "الرجاء اختيار مسار اساسى اخر قبل حذف هذا المسار",
      actionsList: "الإجراءات",
      exceedFlowsLimitMsg:
        "عذرًا لقد وصلت للحد الأقصى ، من فضلك قم بالاشتراك بخطة أعلى لإضافة مسارات أكثر",
    },
    payment: {
      savedCardsTitle: "البطاقات المسجلة",
      defaultCard: "البطاقة الأساسية",
      addNewCard: "أضف بطاقة جديدة",
      selectCardType: "اختر نوع البطاقة",
      enterCardDetails: "ادخل تفاصيل البطاقة",
      active: "مفعل",
      statusAddCardSuccessful: "تمت العملية بنجاح!",
      statusAddCardFailure: "عذرا، لم تتم العملية، رجاء الإعادة مرة أخرى !",
      backToCardsBtn: "الرجوع لبطاقاتي",
      deleteCardTitle: "حذف البطاقة",
      deleteCardContent: "هل أنت متأكد من حذف هذه البطاقة؟",
      deleteDefaultError:
        "لا يمكنك حذف البطاقة المفعلة ، يرجى تفعيل بطاقة أخرى أولاً",
      activeCardTitle: "تفعيل البطاقة",
      activeCardContent:
        "هل أنت متأكد أنك تريد تفعيل هذه البطاقة؟ مما يعني أنه سيتم تحصيل رسوم الاشتراك منه في المستقبل",
      payForSavingCardMsg: "سيتم خصم ريال سعودي واحد من حسابكم لإضافة الكارت",
    },
    billingInfo: {
      billingInfoTitle: "بيانات الفاتورة",
      email: "البريد الالكترونى",
      firstName: "الاسم الأول",
      lastName: "الاسم الخير",
      address: "العنوان",
      country: "الدولة",
      city: "المدينة",
      state: "المنطقة",
      street: "الشارع",
      postcode: "الرمز البريدي",
      saveBillingInfo: "احفظ البيانات للمرة القادمة",
    },
    customSubscriptionPlan: {
      title: "قم ببناء خطتك",
      content:
        "المزيد من المستخدمين، المزيد من الوظائف، المزيد من السير الذاتية، المزيد من دقائق المقابلات، والمزيد.",
      customPlanAction: "تواصل معنا",
    },
    subscribtion: {
      viewReciepe: "عرض الفواتير",
      viewPlan: "اختر خطتك",
      popularPlan: "الأكثر إقبالاً",
      freeTrialEndsIn: "التجربة المجانية تنتهى بعد",
      freeTrialEndedIn: "التجربة المجانية انتهت في",
      days: "ايام",
      hours: "ساعات",
      minutes: "دقائق",
      seconds: "ثوانى",
      subscribe: "أشترك الان",
      ends: "التجربة المجانية تنتهي بعد",
      currentPlan: "التجربة الحالية",
      trialPlan: "التجربة المجانية",
      basicPlan: "الخطة الأساسية",
      advancedPlan: "الخطة المتقدمة",
      premiumPlan: "الخطة المتميزة ",
      usersNo: "عدد المستخدمين",
      jobOpeningsNo: "عدد الوظائف المطروحة شهريًا",
      cvsNo: "عدد السير الذاتية للتقديم شهريًا",
      webcamMinutesNo: "رصيد الدقائق للمقابلات المرئية شهرياً",
      createJobsOnline: "إمكانية إنشاء الوظائف أونلاين",
      socialMedia: "نشر الوظائف عن طريق وسائل التواصل الإجتماعي",

      BrandYourPost: "تصميم منشور إعلانات وظائف المركز",
      DedicatedAccountManager: "مدير حساب خاص للمركز",

      careerQuestioner: "استبيان مهني للمرشحين",
      scoreApplicants: "أسئلة الفرز المسبق المتعلقة بالوظيفة",
      workflow: "اعتماد طلبات التوظيف",

      CustomizePipelineStage: "تصميم مراحل الوظيفة الخاصة بالمركز",
      ManageRolesAndPermissions: "إدارة المستخدمين والصلاحيات",
      ESignatures: "نموذج العرض الوظيفي والتوقيع الإلكتروني",

      jobOfferOnline: "اعتماد العروض الوظيفية",
      companyBrief: "نبذة تعريفية عن المركز",
      linkWebsite: "ربط الموقع الإلكتروني للمركز بصفحة الوظائف",
      customColor: "صفحة وظائف مصممة بهوية المركز ",
      customLogo: "صفحة وظائف بشعار المركز",

      PreScreeningVideoAssessment:
        "أسئلة الفرز المسبق للمرشحين عن طريق الفيديو",
      PreScreeningTextAssessment:
        "أسئلة الفرز المسبق للمرشحين عن طريق نص كتابي",
      LiveVideoInterview: "المقابلات المرئية",

      includeVat: "يشمل ضريبة القيمة المضافة",
      engagementWithCandidates: "نظام التعاقد مع المرشحين",
      subscribeBtn: "اشترك",
      expirationDate: "تاريخ الانتهاء",
      expirationDateTrial: "متاح دائماً",
      freeTrialDays: "ايام تجربة مجانية",
      monthly: "شهريا",
      annual: "سنويا",
      save: "وفر",
      SAR: "ريال سعودى",
      unlimited: "غير محدود",
      payNow: " ادفع الآن",
      paymentSteps: "خطوات الدفع",
      downgradeWarning:
        "تقليل الخطة الخاصة بكم، يمكن أن يسبب فقد التحكم/إدارة بعض البيانات المدخلة",
      cancelSubscription: "الغاء الاشتراك",
      cancelSubscriptionModalHeader: "هل أنت متأكد أنك ترغب بإلغاء اشتراكك ؟",
      cancelSubscriptionModalContent:
        "بإلغاءكم الاشتراك سيتم إيقاف حسابكم على النظام",
      youHave: "لديكم",
      refundStatement: "ر.س، سيتم إعادتها لكم وفقاً لشروط وأحكام HRCom",
      myWallet: "حافظتى",
      walletUseNote: " سوف يتم استخدامها في المعاملات القادمة",
      renew: "تجديد",
      unsubscribe: "إلغاء الاشتراك",
      perMonth: "بالشهر",
      noRegisteredCards: "عذرًا، لا يوجد بطاقات مستخدمة من قبل للعرض",
      billedAnnually: " الدفع سنويًا",
      billedMonthly: " الدفع شهريًا",
      lostDataPipeline: "سيتطلب ذلك حذف إجراء طلب مزيد من المعلومات منً",
      first: "اولا",
      request: "طلب",
      googleSearch: "ظهور الوظائف في نتائج البحث في جوجل",
      overview: "نظرة عامة",
      companyBranding: "هوية المركز",
      matrix: "نظام الاعتمادات والصلاحيات",
      tools: "أدوات التقييم",
      jobPost: "نشر الوظائف",
      customerSuccess: "رعاية العملاء",
      advancedFeatures: "الخيارات المتقدمة",
      sendSMSToCandidate: "إرسال رسائل نصية إلى المتقدمين",
      singleSignOn: "(SSO) الدخول الموحد",
    },
    promoCode: {
      includesVat: "  ضريبة القيمة المضافة %",
      discount: "خصم",
      taxNumber: "310498582100003 : الرقم الضريبي",
      name: "الرمز الترويجى",
      total: "المجموع",
    },
    invoice: {
      tax: "فاتورة ضريبية ",
      invoiceNumber: "رقم الفاتورة",
      monthly: "شهري ",
      companyName: "مركز الجيل الثامن للاتصالات وتقنية المعلومات",
      address: "لرياض، حي الربوة",
      date: "تاريخ ",
      time: "الوقت ",
      vatNumber: "الرقم الضريبي",
      items: "المنتجات",
      quantity: "الكمية ",
      price: "السعر ",
      subtotal: "المجموع ",
      discount: "الخصم ",
      includesVat: "يشمل ضريبة القيمة المضافة (15%)",
      total: "الرصيد المستحق",
      annual: "سنوي ",
      from: "من ",
      address1: " العنوان 1",
      address2: "العنوان 2 ",
      invoiceDate: "تاريخ الإصدار",
      dueDate: "تاريخ التوريد",
      viewInvoice: "عرض الفاتورة",
      to: "الى",
      serialNumber: "الرقم التسلسلي",
      code: "الرمز(وحدة حفظ المخزون)",
      item: "الصنف",
      priceWithoutVat: "الإجمالي غير شامل ضريبة القيمة المضافة",
      vat: "ضريبة القيمة المضافة",
      vatSAR: "ضريبة القيمة المضافة ريال سعودي",
      totalSAR: "الإجمالي ريال سعودي",
      SAR: "ريال سعودى",
      priceIncludesVat: "المجموع شامل ضريبة القيمة المضافة",
    },
    confirmOffer: {
      name: "القيمة",
      Title: "تأكيد قيمة العرض الوظيفي",
      Message: "يرجى تأكيد المجموع الإجمالي للعرض الوظيفي عن طريق إدخاله أدناه",
      totalSalary: "الإجمالي",
      salary: "الراتب الأساسي",
      ShowPercentage: "عرض كنسبه مئويه في العرض الوظيفي ",
    },
    offerTemplateSetup: {
      jobTitle: "المسمي الوظيفي",
      offerAcceptedDate: "تاريخ قبول العرض",
      descriptionAr: "الوصف بالعربية",
      descriptionEn: "الوصف بالانجليزية",
      coverLetter: "الغلاف",
      sendOffer: "ارسال عرض وظيفي",
      ApprovedBy: "تمت الموافقة بواسطة:",
      Signed: "التوقيع:",
      JoiningDate: "التاريخ المتوقع للانضمام :",
      offerJoiningDate: "التاريخ المتوقع للانضمام",
      or: "أو",
      reoffer: "اعادة ارسال",
      offer: "العرض الوظيفي",
      editNotification: "هذا النموذج قابل للتعديل",
    },
    footer: {
      address:
        "الرياض , المملكة العربية السعودية , شارع العليا العام - طريق النور",
    },
    integrations: {
      unifonic: {
        status: "الحالة",
        keyId: "المفتاح",
        senderID: "المرسل",
        description:
          "هو عبارة عن نظام أساسي لمشاركة العملاء، يمكّنك من إسعاد المرشحين بتجربة رائعة",
        active: "مفعل",
        inactive: "غير مفعل",
        activate: "تفعيل",
        edit: "تعديل",
        learnMore: "تعلم أكثر",
      },
      office365: {
        status: "الحالة",
        showCalendar: "عرض التقويم",
        keyId: "المفتاح",
        tenantID: "Office 365 Tenant ID",
        description:
          "تكامل مع حساب Office365 الخاص بمؤسستك لعرض التقويمات بما في ذلك وقت التوفر / الانشغال لكل مستخدم عند جدولة المقابلات",
        active: "مفعل",
        inactive: "غير مفعل",
        activate: "تفعيل",
        edit: "تعديل",
        learnMore: "تعلم أكثر",
        grantAccess: "منح صلاحية",
        requiredGrantAccess:
          "منح الصلاحية إلزامي لاستكمال تفعيل التقويم الخاص بالمركز",
        notGrantAccessed: "الرجاء منح الصلاحية أولاً للحفظ",
        grantAccessNote:
          " رابط منح الصلاحية ، لمنح HRcom حق الوصول إلى تقويم Office 365 الخاصة بمركزك. إذا لم تكن المسؤول عن حساب Office 365 الخاص بك ، فانسخ الرابط وأرسله إلى مسؤول Office 365 الخاص بك لمنحك الصلاحية",
      },
      emailSync: {
        syncAccount: "ربط حسابك",
        connectEmail: "قم بربط بريدك الإلكتروني باتش ار كوم",
        syncEmail: "ربط البريد الإليكتروني",
        delete: "حذف",
        deleteAccount: "مسح الحساب",
        deleteAccountConfirmation: "هل أنت متأكد أنك ترغب في مسح هذا الحساب",
        reminderMessage:
          "يعد ربط حساب بريدك الإلكتروني بـاتش ار كوم أمرًا سهلاً ، ابدأ بتحديد مزود البريد الإلكتروني الخاص بك",
        remindLater: "قم بتذكيري لاحقا",
        donotAllow: "عدم سماح",
      },
    },
    socialLogin: {
      sectionTitle: "أو بواسطة ",
      googleBtn: "جوجل",
      linkedinBtn: "لينكد ان",
      microsoftBtn: "ميكروسوفت",
      prerequestionTitle: "البيانات الأساسية",
    },
    vatNumber: {
      header: "الرقم الضريبي",
      is_saudia_arabian_company: "مركز سعودية",
      vat_number: "الرقم الضريبي",
      notes:
        "نظراً  لسياسات هيئة الزكاة والضريبة والجمارك، من الضروري إدخال الرقم الضريبي الخاص بكم",
    },
    favorite: "المفضل",
    organizationStructure: {
      addDepartment: "إضافة قسم",
      editDepartment: "تعديل القسم",
      departmentNameEn: "اسم القسم بالإنجليزية",
      departmentNameAr: "اسم القسم بالعربية",
      headDepartment: "رئيس القسم",
      hiringManager: "مدير التوظيف",
      headDepartment: "رئيس القسم",
      hiringManager: "مدير التوظيف",
      emptyCaption: "الأن يمكنك إنشاء الهيكل التنظيمي الخاص بك",
      deleteDepartment: "هل ترغب في حذف هذا القسم و الأقسام التابعة له؟",
      deleteDepartmentHeading: "حذف القسم",
    },
    positionChart: {
      levels: "المستويات",
      pleaseSelectLevel: "من فضلك اختر مستوي",
      noData: "لا توجد خيارات ",
      pleaseSelect: "من فضلك اختر",
      JobDescriptionAR: "الوصف الوظيفي بالعربي",
      JobDescriptionEN: "الوصف الوظيفي بالانجليزي",
      addPosition: "إضافة مسمى وظيفي",
      editPosition: "تعديل القسم",
      positionNameEn: "الوظيفة بالإنجليزية",
      positionNameAr: "الوظيفة بالعربية",
      salaryGrade: "سلم الراتب",
      category: "التصنيف",
      emptyCaption: "الأن يمكنك إنشاء التدرج الوظيفي الخاص بك",
      deletePositionHeading: "حذف القسم",
      deletePosition: "هل ترغب في حذف هذه الوظيفة والوظائف التابعة لها؟",
    },
    templates: {
      arabic_template: "قالب اللغة العربية",
      english_template: "قالب اللغة الانجليزية",
    },
  },
  renainingHours: "ساعات تبقى على النسخة التجريبية",
};
