import * as types from "../types/Agent";

export const requestAddNewAgent = (data , type) => {
  return {
    type: types.ADD_NEW_AGENT_REQUEST,
    payload: {data , type}
  };
};

export const receiveAddNewAgent = (data , type) => {
  return {
    type: types.ADD_NEW_AGENT_RECEIVE,
    payload: {data , type}
  };
};



export const requestAgentCandidateProfile = (data , type) => {
  return {
    type: types.AGENT_CANDIDATE_PROFILE_REQUEST,
    payload: data 
  };
};

export const receiveAgentCandidateProfile = (data , type) => {
  return {
    type: types.AGENT_CANDIDATE_PROFILE_RECEIVE,
    payload: {data , type}
  };
};

export const requestAgentJobDetails = (data , type) => {
  return {
    type: types.AGENT_JOB_DETAILS_REQUEST,
    payload: data 
  };
};

export const receiveAgentJobDetails = (data , type) => {
  return {
    type: types.AGENT_JOB_DETAILS_RECEIVE,
    payload: {data , type}
  };
};
export const requestGetAllAgents = data => {
  return {
    type: types.GET_ALL_AGENTS_REQUEST,
    payload: data
  };
};

export const receiveGetAllAgents = data => {
  return {
    type: types.GET_ALL_AGENTS_RECEIVE,
    payload: data
  };
};

export const requestGetAgent = data => {
  return {
    type: types.GET_AGENT_REQUEST,
    payload: data
  };
};

export const receiveGetAgent = data => {
  return {
    type: types.GET_AGENT_RECEIVE,
    payload: data
  };
};

export const requestUpdateAgent = data => {
  return {
    type: types.EDIT_AGENT_REQUEST,
    payload: data
  };
};

export const receiveUpdateAgent = data => {
  return {
    type: types.EDIT_AGENT_RECEIVE,
    payload: data
  };
};

export const requestDeleteAgent = data => {
  return {
    type: types.DELETE_AGENT_REQUEST,
    payload: data
  };
};

export const receiveDeleteAgent = data => {
  return {
    type: types.DELETE_AGENT_RECEIVE,
    payload: data
  };
};

export const requestActivateDeactivateAgent = data => {
  return {
    type: types.ACTIVATE_DEACTIVATE_AGENT_REQUEST,
    payload: data
  };
};

export const receiveActivateDeactivateAgent = data => {
  return {
    type: types.ACTIVATE_DEACTIVATE_AGENT_RECEIVE,
    payload: data
  };
};

export const requestAgentPassword = data => {
  return {
    type: types.ADD_AGENT_PASSWORD_REQUEST,
    payload: data
  };
};

export const receiveAgentPassword = data => {
  return {
    type: types.ADD_AGENT_PASSWORD_RECEIVE,
    payload: data
  };
};

export const requestAgentConfirmation = data => {
  return {
    type: types.IS_AGENT_CONFIRMED_REQUEST,
    payload: data
  };
};

export const receiveAgentConfirmation = data => {
  return {
    type: types.IS_AGENT_CONFIRMED_RECEIVE,
    payload: data
  };
};

export const requestGetAgentProfile = () => {
  return {
    type: types.GET_AGENT_PROFILE_REQUEST,
  };
};

export const receiveGetAgentProfile = data => {
  return {
    type: types.GET_AGENT_PROFILE_RECEIVE,
    payload: data
  };
};

export const requestEditAgentProfile = (data) => {
  return {
    type: types.EDIT_AGENT_PROFILE_REQUEST,
    payload : data
  };
};

export const receiveEditAgentProfile = data => {
  return {
    type: types.EDIT_AGENT_PROFILE_RECEIVE,
    payload: data
  };
};

export const requestChangeAgentPassword = data => {
  return {
    type: types.AGENT_CHANGE_PASSWORD_REQUEST,
    payload : data
  };
};

export const receiveChangeAgentPassword = data => {
  return {
    type: types.AGENT_CHANGE_PASSWORD_RECEIVE,
    payload: data
  };
};

export const requestResendConfirmation = data => {
  return {
    type: types.AGENT_RESEND_CONFIRMATION_REQUEST,
    payload : data
  };
};

export const receiveResendConfirmation = () => {
  return {
    type: types.AGENT_RESEND_CONFIRMATION_RECEIVE,
  };
};

export const googleSyncRequest = () => {
  return {
    type: types.GOOGLE_SYNC_REQUEST,
  };
};

export const microsoftSyncRequest = () => {
  return {
    type: types.MICROSOFT_SYNC_REQUEST,
  };
};

export const deleteSyncEmailRequest=()=>{
  return {
    type: types.DELETE_SYNC_MODAL_REQUEST,
  }; 
}

export const notAllowReminderRequest=()=>{
  return {
    type: types.NOT_ALLOW_REMNDER_REQUEST,
  };  
}