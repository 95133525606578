import * as types from "../types/Organization";

export const recievePositionCharts = (payload) => ({
  type: types.RECIEVE_POSITIONS_CHART,
  payload,
});
export const requestPositionCharts = (payload) => ({
  type: types.REQUEST_POSITIONS_CHART,
  payload,
});
export const recieveOrganizationStructure = (payload) => ({
  type: types.RECIEVE_ORGANIZATION_STRUCTURE,
  payload,
});
export const requestOrganizationStructure = (payload) => ({
  type: types.REQUEST_ORGANIZATION_STRUCTURE,
  payload,
});

export const getActiveAgentsRequest = () => ({
  type: types.GET_ACTIVE_AGENTS_REQUEST,
});

export const getSalaryGradeRequest = () => ({
  type: types.GET_SALARY_GRADE_REQUEST,
});

export const salaryGradeResponse = (payload) => ({
  type: types.SALARY_GRADE_RESPONSE,
  payload,
});

export const getCategoryListRequest = () => ({
  type: types.GET_CATEGORY_LIST_REQUEST,
});

export const categoryListResponse = (payload) => ({
  type: types.CATEGORY_LIST_RESPONSE,
  payload,
});

export const submitAddPositionRequest = (payload) => ({
  type: types.SUBMIT_ADD_POSITION_REQUEST,
  payload,
});

export const activeAgentsResponse = (payload) => ({
  type: types.ACTIVE_AGENTS_RESPONSE,
  payload,
});

export const submitAddDepartmentRequest = (payload) => ({
  type: types.SUBMIT_ADD_DEPARTMENT_REQUEST,
  payload,
});

export const OrganizationDataSubmited = (payload) => ({
  type: types.ORGANIZATION_DATA_SAVED,
  payload,
});

export const clickedNodeData = (payload) => ({
  type: types.CLICKED_NODE_DATA,
  payload,
});

export const getDepartmentDataRequest = (payload) => ({
  type: types.GET_DEPARTMENT_DATA_REQUEST,
  payload,
});

export const editDepartmentRequest=(payload)=>({
  type:types.EDIT_DEPARTMENT_REQUEST,
  payload
})
export const deleteDepartmentRequest=(payload)=>({
  type:types.DELETE_DEPARTMENT_REQUEST,
  payload
})

export const organizationDeletedAction=(payload)=>({
  type:types.ORGANIZATION_DELETED,
  payload
})

export const getPositionDataRequest= (payload) => ({
  type: types.GET_POSITION_DATA_REQUEST,
  payload,
});

export const editPositionRequest= (payload) => ({
  type: types.EDIT_POSITION_REQUEST,
  payload,
});
export const deletePositionRequest=(payload)=>({
  type:types.DELETE_POSITION_REQUEST,
  payload
})