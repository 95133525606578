import * as types from "../types/requests";

const INITIAL_STATE = {
  requests_list: {},
  offer_template: {},
  contract_template: {},
  signature_image: null,
  success_send_offer: {},
  tabs_content: {},
  isUnifunicActive: false,
  candidateTemplate: {},
  accepted_offers:null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.REQUESTS_RECIEVE:
      return {
        ...state,
        requests_list: { ...action.payload },
      };
    case types.GET_OFFER_TEMPLATE_RECEIVE:
      return {
        ...state,
        offer_template: action.payload,
      };
    case types.GET_CONTRACT_TEMPLATE_RECIEVE:
      return {
        ...state,
        contract_template: action.payload,
      };
    case types.SAVE_OFFER_SIGNATURE_RECEIVE:
      return {
        ...state,
        signature_image: action.payload,
      };
    case types.SAVE_OFFER_TEMPLATE_RECEIVE:
      return {
        ...state,
        success_send_offer: action.payload,
      };
    case types.CANDIDATE_REQUESTS_RECIEVE:
      return {
        ...state,
        tabs_content: action.payload,
      };
    case types.APPROVE_CANDIDATE_REQUESTS_RECIEVE:
      return {
        ...state,
        new_expiration_date: action.payload,
      };
    case types.GET_ACTIVE_UNIFUNIC_RECIEVE:
      return {
        ...state,
        isUnifunicActive: action.payload.is_Active_Unifonic,
      };
    case types.RECIEVE_CANDIDATE_OFFER_TEMPLATE:
      return {
        ...state,
        candidateTemplate: action.payload,
      };

      case types.ACCEPTED_OFFERS_RECIEVE:
        return {
          ...state,
          accepted_offers: action.payload,
        };
    default:
      return state;
  }
};
