import { call, put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import API from "../../network/apis/createNewRequestAPI";
import * as ACTION from "../actions/createNewRequest";
import * as type from "../types/createNewRequest";
import { setStepper } from "../../store/actions/stepperActions";
import { requestJobTitlesLookup } from "../actions/lookups";
import history from "../../routes/History";
import store from "../../store";
import {
  dispatchError,
  getURLParams,
  showSnackbar,
  showWarningSnackbar,
} from "../../utils/shared";
import { isUserHasPermission, isSuperAdmin } from "../../utils/Permissions";
import ReactGA from "react-ga";
import { showSnackbarAction } from "store/actions/snackbar";

export function* addJobDetails(action) {
  try {
    const response = yield call(API.postJobDetails, action.payload);
    localStorage.setItem("jobOpeningId", response.data.data);
    yield put(ACTION.receiveAddJobDetails(response.data));
    const editStep = localStorage.getItem("editStep");
    showSnackbar("savedSuccessfuly");
    if (store.getState().jobDetailsRequest.approvalStage === 1 && store.getState().jobDetailsRequest.hasApprovalSetup) {
      history.push({
        pathname: "/viewAllRequests",
        search: "?state=inprogress&page=1",
      });
    }
    if (
      store.getState().stepper.totalSteps ===
      store.getState().stepper.activeStep + 1
    ) {
      localStorage.setItem("createStepperIndex", 0);
    } else {
      localStorage.setItem(
        "createStepperIndex",
        store.getState().stepper.activeStep + 1
      );
      store.dispatch(
        setStepper({
          ...store.getState().stepper,
          activeStep: localStorage.getItem("createStepperIndex"),
          completedSteps: [
            editStep >= 0,
            editStep > 1,
            editStep > 2,
            editStep > 3,
          ],
        })
      );
    }
    yield put(
      ACTION.requestSummaryDetails(localStorage.getItem("jobOpeningId"))
    );
    (isUserHasPermission("JOB_REQUISITION") || isSuperAdmin()) &&
      editStep < 1 &&
      localStorage.setItem("editStep", 1);

    (isUserHasPermission("JOB_REQUISITION") || isSuperAdmin()) &&
    store.getState().jobDetailsRequest.approvalStage !== 1
      ? editStep < 1 &&
        getURLParams("type") !== "published" &&
        history.push(
          `/manpowerRequests/${response.data.data}?type=inprogress&hasCandidate=false`
        )
      : history.push({
          pathname: "/viewAllRequests",
          search: "?state=inprogress&page=1",
        });
      localStorage.getItem("addStep") < 1 &&
      localStorage.setItem("addStep", 1);

    yield put(ACTION.setIsEditMode(true));
    ReactGA.event({
      category: `${localStorage.getItem("domain")} : Initiate manpower request`,
      action: "User Initiate manpower request",
    });
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* manageJobRequisitionValidation({ payload }) {
  try {
    const response = yield call(API.manageJobRequisitionValidation, payload);
    yield put(ACTION.receiveManageJobRequisitionValidation(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* manageJobRequisition({ payload }) {
  try {
    const response = yield call(API.manageJobRequisition, payload);
    yield put(ACTION.receiveManageJobRequisition(response.data));
    const editStep = localStorage.getItem("editStep");
    showSnackbar("savedSuccessfuly");
    if (store.getState().jobDetailsRequest.approvalStage === 2) {
      history.push({
        pathname: "/viewAllRequests",
        search: "?state=inprogress&page=1",
      });
    }
    if (
      store.getState().stepper.totalSteps ===
      store.getState().stepper.activeStep + 1
    ) {
      history.push({
        pathname: "/viewAllRequests",
        search: "?state=inprogress&page=1",
      });
      localStorage.setItem("createStepperIndex", 0);
    } else {
      localStorage.setItem(
        "createStepperIndex",
        store.getState().stepper.activeStep + 1
      );
      store.dispatch(
        setStepper({
          ...store.getState().stepper,
          activeStep: localStorage.getItem("createStepperIndex"),
          completedSteps: [
            editStep > 0,
            editStep >= 1,
            editStep > 2,
            editStep > 3,
          ],
        })
      );
    }
    yield put(
      ACTION.requestSummaryDetails(localStorage.getItem("jobOpeningId"))
    );
    editStep < 2 && localStorage.setItem("editStep", 2);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* manageJobRequisitionSetup() {
  try {
    const response = yield call(API.manageJobRequisitionSetup);
    yield put(ACTION.receiveJobRequestJobRequisition(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* manageScreeningQuestions({ payload }) {
  try {
    const response = yield call(API.manageScreeningQuestions, payload);
    yield put(ACTION.receiveManageScreeningQuestions(response.data));
    const editStep = localStorage.getItem("editStep");
    showSnackbar("savedSuccessfuly");
    if (
      store.getState().stepper.totalSteps ===
      store.getState().stepper.activeStep + 1
    ) {
      history.push({
        pathname: "/viewAllRequests",
        search: "?state=inprogress&page=1",
      });
      localStorage.setItem("createStepperIndex", 0);
    } else {
      localStorage.setItem(
        "createStepperIndex",
        store.getState().stepper.activeStep + 1
      );
      store.dispatch(
        setStepper({
          ...store.getState().stepper,
          activeStep: localStorage.getItem("createStepperIndex"),
          completedSteps: [
            editStep > 0,
            editStep > 1,
            editStep >= 2,
            editStep > 3,
          ],
        })
      );
    }
    editStep < 3 && localStorage.setItem("editStep", 3);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* validateManageScreeningQuestions({ payload }) {
  try {
    const response = yield call(API.validateManageScreeningQuestions, payload);
    yield put(ACTION.receiveValidateManageScreeningQuestions(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getLibraryQuestions() {
  try {
    const response = yield call(API.getLibraryQuestions);
    yield put(
      ACTION.receiveGetLibraryQuestions(
        response.data.data.screening_question_section
      )
    );
  } catch (err) {
    console.log(err);
  }
}
export function* requestSummaryDetails({ payload }) {
  try {
    const response = yield call(API.requestSummaryDetails, payload);
    yield put(ACTION.receiveSummaryDetails(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
export function* requestPublishJobPost({ payload }) {
  try {
    const response = yield call(API.requestPublishJobPost, payload);
    yield put(ACTION.receivePublishJobPost(response.data.data));
    // var message =
    //   localStorage.getItem("lang") === "en"
    //     ? "You are still able to post more " +
    //       response.data.data.remaining_job +
    //       " jobs this month."
    //     : "لا يزال بإمكانك نشر " +
    //       response.data.data.remaining_job +
    //       " وظيفة أخرى هذا الشهر";
    // showWarningSnackbar(message);
  } catch (err) {
    if (err.response?.data.error.message == "QuotaFininshed") {
      var message =
        localStorage.getItem("lang") === "en"
          ? "Your job has been saved, but you have reached the max. number of jobs to be posted this month"
          : "تم حفظ وظيفتك ، لكنك بلغت الحد الأقصى للوظائف المنشورة لهذا الشهر";
      showWarningSnackbar(message);
    } else dispatchError(err.response?.data);
  }
}

export function* requestJobRequisitionCopyAllTemplate() {
  try {
    const response = yield call(API.requestJobRequisitionCopyAllTemplate);
    yield put(ACTION.receiveJobRequisitionCopyAllTemplate(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* requestJobRequisitionCopySingleTemplate({ payload }) {
  try {
    const response = yield call(
      API.requestJobRequisitionCopySingleTemplate,
      payload
    );
    yield put(
      ACTION.receiveJobRequisitionCopySingleTemplate(response.data.data)
    );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestScreeningCopyAllTemplate() {
  try {
    const response = yield call(API.requestScreeningCopyAllTemplate);
    yield put(ACTION.receiveScreeningCopyAllTemplate(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestScreeningCopySingleTemplate({ payload }) {
  try {
    const response = yield call(
      API.requestScreeningCopySingleTemplate,
      payload
    );
    yield put(ACTION.receiveScreeningCopySingleTemplate(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestAdvertisementPreview({ payload }) {
  try {
    const response = yield call(API.requestAdvertisementPreview, payload);
    yield put(ACTION.receiveAdvertisementPreview(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestPublishAdvertisement({ payload }) {
  try {
    const response = yield call(API.requestPublishAdvertisement, payload);
    yield put(ACTION.receivePublishAdvertisement(response.data.data));
    // var message =
    //   localStorage.getItem("lang") === "en"
    //     ? "You are still able to post more " +
    //       response.data.data.remaining_job +
    //       " jobs this month."
    //     : "لا يزال بإمكانك نشر " +
    //       response.data.data.remaining_job +
    //       " وظيفة أخرى هذا الشهر";
    // showWarningSnackbar(message);
  } catch (err) {
    if (err.response?.data.error.message == "QuotaFininshed") {
      var message =
        localStorage.getItem("lang") === "en"
          ? "Your job has been saved, but you have reached the max. number of jobs to be posted this month"
          : "تم حفظ وظيفتك ، لكنك بلغت الحد الأقصى للوظائف المنشورة لهذا الشهر";
      showWarningSnackbar(message);
    } else dispatchError(err.response?.data);
  }
}

export function* requestHasApprovalSaga({ payload }) {
  try {
    const response = yield call(API.requestHasApproval, payload);
    yield put(ACTION.receiveHasApprovalSetup(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

function* requestAddNewJobTitle({ payload }) {
  try {
    const response = yield call(API.requestAddNewJobTitle, payload);
    yield put(ACTION.receiveAddNewJobTitle(response.data.data));
    showSnackbar("savedSuccessfuly");
    yield put(requestJobTitlesLookup());
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestDisableScreeningQuestions() {
  try {
    const response = yield call(API.requestDisableScreening);
    yield put(ACTION.receiveDisableScreeningQuestions(response.data.data));
  } catch (error) {
    console.log(error);
  }
}
function* getApprovalStageRequest() {
  try {
    const response = yield call(API.getApprovalStageRequest);
    yield put(ACTION.getApprovalStageRecieve(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* postDrJob() {
  try {
    const response = yield call(API.postDrJob);
    yield put(ACTION.receiveDrJobPost(response.data));
    showSnackbar("savedSuccessfulyDrJob");
  } catch (error) {
    console.log(error);
  }
}
function* approvalSummaryRequest({ payload }) {
  try {
    yield call(API.sendApprovalInSUmmaryStage, payload);
    yield call(getApprovalStageRequest);
    history.push({
      pathname: "/viewAllRequests",
      search: "?state=inprogress&page=1",
    });
  } catch (error) {
    console.log(error);
  }
}

function* deleteScreeningTemplate({ payload }) {
  try {
    yield call(API.deleteScreeningTemplate, payload);
    yield requestScreeningCopyAllTemplate(); 
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* deleteJobRequestionTemplate({ payload }) {
  try {
    yield call(API.deleteJobRequestionTemplate, payload);
    yield requestJobRequisitionCopyAllTemplate();
    showSnackbar("done")   
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* ManpowerRequestSagaWatch() {
  yield takeLatest(type.ADD_JOB_DETAILS_REQUEST, addJobDetails);
  yield takeLatest(
    type.MANAGE_JOB_REQUISITION_VALIDATION_REQUEST,
    manageJobRequisitionValidation
  );
  yield takeLatest(type.MANAGE_JOB_REQUISITION_REQUEST, manageJobRequisition);
  yield takeLatest(
    type.REQUEST_JOB_REQUEST_JOB_REQUESITION,
    manageJobRequisitionSetup
  );
  yield takeLatest(
    type.POST_MANAGE_SCREENING_QUESTIONS_REQUEST,
    manageScreeningQuestions
  );
  yield takeLatest(
    type.POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_REQUEST,
    validateManageScreeningQuestions
  );
  yield takeLatest(type.GET_LIBRARY_QUESTIONS_REQUEST, getLibraryQuestions);
  yield takeLatest(type.REQUEST_SUMMARY_DETAILS, requestSummaryDetails);
  yield takeLatest(type.REQUEST_PUBLISH_JOB_POST, requestPublishJobPost);
  yield takeLatest(
    type.JOB_REQUISITION_COPY_ALL_TEMPLATE_REQUEST,
    requestJobRequisitionCopyAllTemplate
  );
  yield takeLatest(
    type.JOB_REQUISITION_COPY_SINGLE_TEMPLATE_REQUEST,
    requestJobRequisitionCopySingleTemplate
  );
  yield takeLatest(
    type.SCREENING_COPY_ALL_TEMPLATE_REQUEST,
    requestScreeningCopyAllTemplate
  );
  yield takeLatest(
    type.SCREENING_COPY_SINGLE_TEMPLATE_REQUEST,
    requestScreeningCopySingleTemplate
  );
  yield takeLatest(
    type.REQUEST_ADVERTISEMENT_PREVIEW,
    requestAdvertisementPreview
  );
  yield takeLatest(
    type.REQUEST_PUBLISH_ADVERTISEMENT,
    requestPublishAdvertisement
  );
  yield takeLatest(type.REQUEST_HAS_APPROVAL_SETUP, requestHasApprovalSaga);
  yield takeLatest(type.ADD_NEW_JOB_TITLE_REQUEST, requestAddNewJobTitle);
  yield takeLatest(
    type.DISABLE_SCREENING_QUESTIONS_REQUEST,
    requestDisableScreeningQuestions
  );
  yield takeLatest(type.GET_JOB_APPROVAL_REQUEST, getApprovalStageRequest);
  yield takeLatest(type.REQUEST_DR_JOB_POST, postDrJob);
  yield takeLatest(
    type.SEND_JOB_APPROVAL_IN_SUMMARY_REQUEST,
    approvalSummaryRequest
  );
  yield takeLatest(
    type.DELETE_JOB_REQUESTION_TEMPLATE,
    deleteJobRequestionTemplate
  );
  yield takeLatest(type.DELETE_SCREENING_TEMPLATE, deleteScreeningTemplate);
}
