import { axiosInstance } from "./config";

let handlerEnabled = true;

const getRecruitmentPlan = async () => {
  return await axiosInstance.get(`/admin/recruitment_plan`, {
    handlerEnabled,
  });
};
const setRecruitmentPlan = async (data) => {
  return await axiosInstance.post(`/admin/recruitment_plan`, data, {
    handlerEnabled,
  });
};

export default {
  getRecruitmentPlan,
  setRecruitmentPlan,
};
