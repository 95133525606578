export const PERMISSIONS = {
  SETUP: 1,
  JOB_DETAILS: 2,
  JOB_REQUISITION: 3,
  CREATE_JOB_TITLE: 4,
  APPROVE_CANDIDATE_REQUESTS: 7,
  TALENT_POOL_LIST: 8,
  EDIT_JOBS: 9,
  DELETE_TEMPLATE: 10,
  VIEW_PENDING_JOB_REQUESTS: 51,
  EDIT_PENDING_JOB_REQUESTS: 52,
  DELETE_PENDING_JOB_REQUESTS: 53,
  LIST_JOB_REQUESTS: 54,
  VIEW_PUBLISHED_JOB_REQUESTS: 55,
  COPY_PUBLISHED_JOB_REQUESTS: 56,
  CLOSE_PUBLISHED_JOB_REQUESTS: 57,
  LIST_ALL_JOB_OPENINGS: 58,
  CHANGE_CLOSING_DATE_ALL_JOB_OPENINGS: 59,
  MANAGE_CANDIDATES_ALL_JOB_OPENINGS: 60,
  VIEW_CLOSED_JOB_REQUESTS: 61,
  MY_INTERVIEW_GRID: 62,
  APPROVAL: 63,
  CLOSE_RUNNING_JOBS: 64,
  HOLD_PUBLISHED_JOBS: 65,
  DUPLICATE_JOBS: 66,
  VIEW_RECRUITER_JOBS_PENDING_ONLY: 68,
  VIEW_RECRUITER_PUBLISHED_JOBS_ONLY: 69,
  ADD_APPLICANT: 70,
  ADD_TO_JOB_OPENING: 71,
  VIEW_INTEGRATION: 74,
  EDIT_INTEGRATION: 75,
  VIEW_EMAIL_TEMPLATES: 76,
  CREATE_EMAIL_TEMPLATES: 77,
  EDIT_EMAIL_TEMPLATES: 78,
  DELETE_EMAIL_TEMPLATES: 79,
  VIEW_REPORTS: 80,
  VIEW_REJECTION_REASON: 81,
  ADD_REJECTION_REASON: 82,
  Edit_REJECTION_REASON: 83,
  DELETE_REJECTION_REASON: 84,
  UPDATE_CONTARCT_TEMPLATE: 85,
  VIEW_INTERVIEW_EVALUATION_FORM: 98,
  EDIT_INTERVIEW_EVALUATION_FORM: 99,
  VIEW_JOB_TITLE: 100,
  ADD_JOB_TITLE: 101,
  EDIT_JOB_TITLE: 102,
  DELETE_JOB_TITLE: 103,
  VIEW_ORGANIZATIONAL_STRUCTURE: 104,
  ADD_ORGANIZATIONAL_STRUCTURE: 105,
  EDIT_ORGANIZATIONAL_STRUCTURE: 106,
  DELETE_ORGANIZATIONAL_STRUCTURE: 107,
  SETUP_APPROVAL_CYCLE: 150,
  MANAGE_RECRUITMENT_PLAN: 151,
  MANAGE_CANDIDATE_PROFILE : 152,
  MANAGE_JOB_DETAILS : 153,
  ALL_JOBS_STATISTICS:154,
  COMPETENCES_SETTING : 157
  
  
};

export const ConvertPermissions = () => {
  if (localStorage.getItem("token") && localStorage.getItem("permissions")) {
    let permissions = localStorage.getItem("permissions").split(",");
    let PERMISSIONS_ARR = [];
    permissions.map((user_permission) => {
      Object.keys(PERMISSIONS).map(function (key) {
        if (PERMISSIONS[key] == user_permission) {
          PERMISSIONS_ARR.push(key);
        }
      });
    });
    return PERMISSIONS_ARR;
  }
};

export const isUserHasPermission = (permission) => {
  let USER_PERMISSIONS = ConvertPermissions();
  return USER_PERMISSIONS ? USER_PERMISSIONS.includes(permission) : null;
};

export const isSuperAdmin = () => {
  if (localStorage.getItem("token") && localStorage.getItem("roles")) {
    let rolesArr = localStorage.getItem("roles").split(",");
    let isSuperAdmin = rolesArr.includes("SUPER_ADMIN");
    return isSuperAdmin;
  }
};
