import * as types from "../types/createNewRequest";

export const requestAddJobDetails = (data) => {
  return {
    type: types.ADD_JOB_DETAILS_REQUEST,
    payload: data,
  };
};

export const receiveAddJobDetails = (data) => {
  return {
    type: types.ADD_JOB_DETAILS_RECEIVE,
    payload: data,
  };
};

export const requestManageJobRequisitionValidation = (payload) => ({
  type: types.MANAGE_JOB_REQUISITION_VALIDATION_REQUEST,
  payload,
});

export const receiveManageJobRequisitionValidation = (payload) => ({
  type: types.MANAGE_JOB_REQUISITION_VALIDATION_RECEIVE,
  payload,
});

export const requestManageJobRequisition = (payload) => ({
  type: types.MANAGE_JOB_REQUISITION_REQUEST,
  payload,
});

export const receiveManageJobRequisition = (payload) => ({
  type: types.MANAGE_JOB_REQUISITION_RECEIVE,
  payload,
});

export const requestJobRequestJobRequisition = () => ({
  type: types.REQUEST_JOB_REQUEST_JOB_REQUESITION,
});

export const receiveJobRequestJobRequisition = (payload) => ({
  type: types.RECEIVE_JOB_REQUEST_JOB_REQUESITION,
  payload,
});

export const requestManageScreeningQuestions = (payload) => ({
  type: types.POST_MANAGE_SCREENING_QUESTIONS_REQUEST,
  payload,
});

export const receiveManageScreeningQuestions = (payload) => ({
  type: types.POST_MANAGE_SCREENING_QUESTIONS_RECEIVE,
  payload,
});

export const requestValidateManageScreeningQuestions = (payload) => ({
  type: types.POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_REQUEST,
  payload,
});

export const receiveValidateManageScreeningQuestions = (payload) => ({
  type: types.POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_RECEIVE,
  payload,
});

export const requestGetLibraryQuestions = () => ({
  type: types.GET_LIBRARY_QUESTIONS_REQUEST,
});

export const receiveGetLibraryQuestions = (payload) => ({
  type: types.GET_LIBRARY_QUESTIONS_RECEIVE,
  payload,
});
export const requestSummaryDetails = (payload) => ({
  type: types.REQUEST_SUMMARY_DETAILS,
  payload,
});
export const receiveSummaryDetails = (payload) => ({
  type: types.RECEIVE_SUMMARY_DETAILS,
  payload,
});
export const requestPublishJobPost = (payload) => ({
  type: types.REQUEST_PUBLISH_JOB_POST,
  payload,
});
export const receivePublishJobPost = (payload) => ({
  type: types.RECEIVE_PUBLISH_JOB_POST,
  payload,
});

export const requestJobRequisitionCopyAllTemplate = () => ({
  type: types.JOB_REQUISITION_COPY_ALL_TEMPLATE_REQUEST,
});
export const receiveJobRequisitionCopyAllTemplate = (payload) => ({
  type: types.JOB_REQUISITION_COPY_ALL_TEMPLATE_RECEIVE,
  payload,
});
export const requestJobRequisitionCopySingleTemplate = (payload) => ({
  type: types.JOB_REQUISITION_COPY_SINGLE_TEMPLATE_REQUEST,
  payload,
});
export const receiveJobRequisitionCopySingleTemplate = (payload) => ({
  type: types.JOB_REQUISITION_COPY_SINGLE_TEMPLATE_RECEIVE,
  payload,
});

export const requestScreeningCopyAllTemplate = () => ({
  type: types.SCREENING_COPY_ALL_TEMPLATE_REQUEST,
});
export const receiveScreeningCopyAllTemplate = (payload) => ({
  type: types.SCREENING_COPY_ALL_TEMPLATE_RECEIVE,
  payload,
});
export const requestScreeningCopySingleTemplate = (payload) => ({
  type: types.SCREENING_COPY_SINGLE_TEMPLATE_REQUEST,
  payload,
});
export const receiveScreeningCopySingleTemplate = (payload) => ({
  type: types.SCREENING_COPY_SINGLE_TEMPLATE_RECEIVE,
  payload,
});
export const requestAdvertisementPreview = (payload) => ({
  type: types.REQUEST_ADVERTISEMENT_PREVIEW,
  payload,
});
export const receiveAdvertisementPreview = (payload) => ({
  type: types.RECEIVE_ADVERTISEMENT_PREVIEW,
  payload,
});

export const requestPublishAdvertisement = (payload) => ({
  type: types.REQUEST_PUBLISH_ADVERTISEMENT,
  payload,
});
export const receivePublishAdvertisement = (payload) => ({
  type: types.RECEIVE_PUBLISH_ADVERTISEMENT,
  payload,
});

export const setIsEditMode = (payload) => ({
  type: types.IS_JOB_REQUEST_EDIT_MODE,
  payload,
});

export const requestHasApprovalSetup = (payload) => ({
  type: types.REQUEST_HAS_APPROVAL_SETUP,
  payload
});
export const receiveHasApprovalSetup = (payload) => ({
  type: types.RECEIVE_HAS_APPROVAL_SETUP,
  payload,
});

export const requestAddNewJobTitle = (payload) => ({
  type: types.ADD_NEW_JOB_TITLE_REQUEST,
  payload,
});
export const receiveAddNewJobTitle = (payload) => ({
  type: types.ADD_NEW_JOB_TITLE_RECEIVE,
  payload,
});

export const requestDisableScreeningQuestions = () => ({
  type: types.DISABLE_SCREENING_QUESTIONS_REQUEST,
});
export const receiveDisableScreeningQuestions = (payload) => ({
  type: types.DISABLE_SCREENING_QUESTIONS_RECEIVE,
  payload,
});
export const getApprovalStageRequest = () => ({
  type: types.GET_JOB_APPROVAL_REQUEST,
});
export const getApprovalStageRecieve = (payload) => ({
  type: types.GET_JOB_APPROVAL_RECIEVE,
  payload,
});
export const sendApprovalInSUmmaryStage = (payload) => ({
  type: types.SEND_JOB_APPROVAL_IN_SUMMARY_REQUEST,
  payload,
});

export const requestDrJobPost = () => ({
  type: types.REQUEST_DR_JOB_POST,
});
export const receiveDrJobPost = (payload) => ({
  type: types.RECEIVE_DR_JOB_POST,
  payload,
});
export const deleteJobRequestionTemplate = (payload) => ({
  type: types.DELETE_JOB_REQUESTION_TEMPLATE,
  payload,
});
export const deleteScreeningTemplate = (payload) => ({
  type: types.DELETE_SCREENING_TEMPLATE,
  payload,
});
