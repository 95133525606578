import { call, put } from "redux-saga/effects";
import API from "../../network/apis/viewAllRequestsAPI";
import * as ACTION from "../actions/viewAllRequests";
import { dispatchError, showSnackbar, getURLParams } from "../../utils/shared";
import store from "../../store";
import History from "./../../routes/History";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/viewAllRequests";

export function* requestRequestsInProgress({ payload }) {
  try {
    const response = yield call(API.requestRequestsInProgress, payload);
    yield put(ACTION.receiveRequestsInProgress(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
export function* deleteRequestInprogress({ payload }) {
  try {
    yield call(API.deleteRequestInprogress, payload.requestId);
    yield put(ACTION.firstViewALLRequestJobOpeningRequest());
    yield put(ACTION.requestRequestsInProgress(payload.pageNumber));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* publishedJobs({ payload }) {
  try {
    const publishedJobsRes = yield call(API.requestPublishedJobs, payload);
    yield put(ACTION.publishedJobsRecieve(publishedJobsRes.data));
  } catch (error) {}
}

export function* requestRequestsClosed({ payload }) {
  try {
    const response = yield call(API.requestRequestsClosed, payload);
    yield put(ACTION.receiveRequestsClosed(response.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestAllJobOpenings({ payload }) {
  try {
    const response = yield call(API.requestAllJobOpenings, payload);
    yield put(ACTION.receiveAllJobOpenings(response.data));
  } catch (error) {}
}

export function* delayClosingDate({ payload }) {
  try {
    yield call(API.delayClosingDate, {
      id: payload.id,
      closing_date: payload.closing_date,
    });
    showSnackbar("done");
    payload.type === "reopen"
      ? yield put(ACTION.reOpenHoldedJobsRequest(payload.id))
      : yield put(
          ACTION.requestAllJobOpenings(
            History.location.search.split("=")[1] || 1
          )
        );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestApprovalsListFlow({ payload }) {
  try {
    const response = yield call(API.requestApprovalStatusFlow, payload);
    yield put(ACTION.receiveApprovalStatusFLow(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* requestCompanyInterviewMinutes() {
  try {
    const response = yield call(API.requestCompanyInterviewMinutes);
    yield put(ACTION.receiveCompanyInterviewMinutes(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* requestCheckJobsLimit() {
  try {
    const response = yield call(API.checkJobsLimit);
    yield put(ACTION.receiveCheckJobsLimit(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* closeJobOpening({ payload: { id, src } }) {
  try {
    yield call(API.closeJobOpening, id);
    showSnackbar("done");
    src === "publishedJobs"
      ? yield put(ACTION.publishedJobsRequset(getURLParams("page")))
      : src === "holdedJobs"
      ? yield put(ACTION.getHoldedJobsRequest(getURLParams("page")))
      : yield put(ACTION.requestAllJobOpenings(getURLParams("page")));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

export function* requestGetHoldedJobsList({ payload }) {
  try {
    const response = yield call(API.getHoldingJobsList, payload);
    yield put(ACTION.getHoldedJobsReceive(response.data));
  } catch (error) {
    console.log(error.response?.data);
  }
}

function* holdPublishedJobs({ payload: { id, src } }) {
  try {
    yield call(API.holdJobOpening, id);
    showSnackbar("done");
    src === "publishedJobs"
      ? yield put(ACTION.publishedJobsRequset(getURLParams("page")))
      : yield put(ACTION.requestAllJobOpenings(getURLParams("page")));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* reOpenHoldedJobs({ payload }) {
  try {
    yield call(API.reOpenJobOpening, payload);
    getURLParams("state") === "holded"
      ? yield put(ACTION.getHoldedJobsRequest(getURLParams("page")))
      : yield put(ACTION.requestRequestsClosed(getURLParams("page")));
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}

function* duplicateJobPost({ payload }) {
  try {
    const response = yield call(API.duplicateJobPost, payload);
    History.push(
      `/manpowerRequests/${response.data.data}?type=published&hasCandidate=false`
    );
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response?.data);
  }
}
function* firstViewALLRequestJobOpeningRequest() {
  try {
    const response = yield call(API.firstViewALLRequestJobOpeningRequest);
    yield put(ACTION.firstViewALLRequestJobOpeningRecieve(response.data.data));
  } catch (error) {
    dispatchError(error.response?.data);
  }
}


export function* toggleArchive({payload}) {
  console.log(payload)

  try {
    yield call(API.toggleArchive, payload.id);
    showSnackbar("done");
    yield put(
      ACTION.requestAllJobOpenings({
        pageNumber:   History.location.search.split("=")[1] || 1,
        params: {
          status: getURLParams("status") ? [getURLParams("status")] : "",

        },
        is_archived:  payload.is_archived
       } ))    
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* ViewAllRequestsSagasWatch() {
  yield takeLatest(
    type.REQUEST_REQUESTS_IN_PROGRESS,
    requestRequestsInProgress
  );
  yield takeLatest(type.REQUESTS_CLOSED_REQUEST, requestRequestsClosed);
  yield takeLatest(type.PUBLISHED_JOBS_REQUEST, publishedJobs);
  yield takeLatest(type.REQUEST_ALL_JOB_OPENINGS, requestAllJobOpenings);
  yield takeLatest(type.DELAY_CLOSING_DATE_REQUSET, delayClosingDate);
  yield takeLatest(type.DELETE_REQUEST_IN_PROGRESS, deleteRequestInprogress);
  yield takeLatest(
    type.APPROVAL_SETUP_STATUS_FLOW_REQUEST,
    requestApprovalsListFlow
  );
  yield takeLatest(
    type.COMPANY_INTERVIEW_MINUTES_REQUEST,
    requestCompanyInterviewMinutes
  );
  yield takeLatest(
    type.CHECK_JOB_REQUESTS_PLAN_LIMIT_REQUEST,
    requestCheckJobsLimit
  );
  yield takeLatest(type.CLOSE_JOB_OPENING_REQUEST, closeJobOpening);
  yield takeLatest(type.GET_HOLDED_JOBS_REQUEST, requestGetHoldedJobsList);
  yield takeLatest(type.HOLD_PUBLISHED_JOBS_REQUEST, holdPublishedJobs);
  yield takeLatest(type.REOPEN_HOLDED_JOBS_REQUEST, reOpenHoldedJobs);
  yield takeLatest(type.DUPLICATE_JOB_POST_REQUEST, duplicateJobPost);
  yield takeLatest(type.TOGGLE_ARCHIVE_REQUSET, toggleArchive);

  yield takeLatest(
    type.FIRST_VIEW_ALL_REQUEST_AND_JOB_OPENING_REQUEST,
    firstViewALLRequestJobOpeningRequest
  );
}
