import * as types from "../types/Organization";

const INITIAL_STATE = {
  graphOrganizationStructure: [],
  graphPositionsChart: [],
  salaryGrade: [],
  categoryList: [],
  activeAgents: [],
  dataSubmited: false,
  clickedNodeData:{},
  isOrganizationDeleteModal:false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.RECIEVE_ORGANIZATION_STRUCTURE:
      return {
        ...state,
        graphOrganizationStructure: action.payload,
      };
    case types.RECIEVE_POSITIONS_CHART:
      return {
        ...state,
        graphPositionsChart: action.payload,
      };
    case types.SALARY_GRADE_RESPONSE:
      return { ...state, salaryGrade: action.payload };
    case types.CATEGORY_LIST_RESPONSE:
      return { ...state, categoryList: action.payload };
    case types.ACTIVE_AGENTS_RESPONSE:
      return { ...state, activeAgents: action.payload };
    case types.ORGANIZATION_DATA_SAVED: 
    return { ...state, dataSubmited: action.payload };
    case types.CLICKED_NODE_DATA: 
    return { ...state, clickedNodeData: action.payload };
    case types.ORGANIZATION_DELETED: 
    return { ...state, isOrganizationDeleteModal: action.payload };
    
    default:
      return state;
  }
};
