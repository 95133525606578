import { axiosInstance } from "./config";

let handlerEnabled = true;

const dashboardSettingsData = async () => {
  return await axiosInstance.get(`/admin/dashboard/cards`, { handlerEnabled });
};
const reportRequest = async () => {
  return await axiosInstance.get("/admin/dashboard/company_portal");
};
const downloadPDFFileRequest = async ({ id }) => {
  return await axiosInstance.get(
    `/admin/offers/downloadOffer_AttachFile/${id}`,
    {
      responseType: "arraybuffer",
      disableLoader: true,
    }
  );
};
const requestVerifyEmail = async ({ user_id }) => {
  return await axiosInstance.post(`/Company/ResendConfirmEmail`, user_id, {
    handlerEnabled,
  });
};
export default {
  dashboardSettingsData,
  reportRequest,
  downloadPDFFileRequest,
  requestVerifyEmail,
};
