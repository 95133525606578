import { axiosInstance } from "./config";

let handlerEnabled = true;

const getCandidateProfileSetup = async () => {
  return await axiosInstance.get("/candidate/CandidateProfileSetup", {
    handlerEnabled,
  });
}

const editCandidateProfileSetup = async (data) => {
  return await axiosInstance.post("/candidate/Candidate/ProfileSetup", data, {
    handlerEnabled,
  });
};

export default {
  getCandidateProfileSetup,
  editCandidateProfileSetup,
};
