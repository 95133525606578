import { axiosInstance } from "./config";

let handlerEnabled = true;

  const getCompetenceSettings = async () => {
    return await axiosInstance.get("/admin/competence_settings/levels", {
      handlerEnabled,
    });
  };

  const editCompetenceSettings = async (data) => {
    return await axiosInstance.put("/admin/competence_settings/levels",data ,{
      handlerEnabled,
    });
  };

  const getCompetenceSettingscategories= async () => {
    return await axiosInstance.get("/admin/competence_settings/categories" ,{
      handlerEnabled,
    });
  };
  const addCompetenceSettingscategories= async (data) => {
    return await axiosInstance.post("/admin/competence_settings/categories" ,data,{
      handlerEnabled,
    });
  };



  const addCompetenceSettingsItems= async (data) => {
    return await axiosInstance.post("/admin/competence_settings/items" ,data,{
      handlerEnabled,
    });
  };


  const editCompetenceSettingscategories= async (data) => {
    return await axiosInstance.put(`/admin/competence_settings/categories/${data.id}` ,data,{
      handlerEnabled,
    });
  };


  const editCompetenceSettingsItems= async (data) => {
    return await axiosInstance.put(`/admin/competence_settings/items/${data.id}` ,data,{
      handlerEnabled,
    });
  };

  const deleteCompetenceSettingscategories= async (id) => {
    return await axiosInstance.delete(`/admin/competence_settings/categories/${id}` ,{
      handlerEnabled,
    });
  };
  const deleteCompetenceSettingsItems= async (id) => {
    return await axiosInstance.delete(`/admin/competence_settings/items/${id}` ,{
      handlerEnabled,
    });
  };


  const getCompetenceSettingscategoriesHaveItems = async () => {
    return await axiosInstance.get("/admin/job_title/lookup/competence_categories", {
      handlerEnabled,
    });
  };

  const getCompetenceLookup = async () => {
    return await axiosInstance.get("/lookup/competencies/get", {
      handlerEnabled,
    });
  };
export default {
  getCompetenceSettings,
  editCompetenceSettings,
  getCompetenceSettingscategories,
  addCompetenceSettingscategories,
  editCompetenceSettingscategories,
  deleteCompetenceSettingscategories,
  deleteCompetenceSettingsItems,
  editCompetenceSettingsItems,
  addCompetenceSettingsItems,
  getCompetenceSettingscategoriesHaveItems,
  getCompetenceLookup
}  