import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import { isUserHasPermission, isSuperAdmin } from "../../utils/Permissions";
import { setClickedPageIndex } from "../../store/actions/clickedPage";
import { useSelector, useDispatch } from "react-redux";
import logoPlaceholder from "../../assets/images/placeholder.jpg";
import { injectIntl } from "react-intl";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import logo from "../../assets/images/0-01.png";
import {
  DashboardIcon,
  RequestIcon,
  ReportIcon,
  ManpowerRequestIcon,
  TalentPoolIcon,
  InterviewIcon,
  JobOpeningIcon,
  SettingIcon,
  OrganizationalStructureIcon,
} from "../SideMenu/InjectedImages";
import "./DrawerMenu.scss";

const DrawerMenu = ({ intl }) => {
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
  });
  const activePageIndex = useSelector((state) => state.activePageIndex.index);
  const lang = useSelector((state) => state.locale.lang);
  const [open, setOpen] = useState(false);
  const [openApprovals, setOpenApprovals] = useState(false);
  const sideMenu = useSelector((state) => state.sideMenu);
  const [openOrganization, setOpenOrganization] = useState(false);
  const dispatch = useDispatch();
  const { messages } = intl;
  const classes = useStyles();
  const { logo_url: companyLogo } = useSelector((state) => state.company);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (open === false) {
      setOpen(false);
      setOpenApprovals(false);
    }
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const handleListItemClick = (event, index) => {
    toggleDrawer(state.side, false);
    setState({ ...state, left: false });
    dispatch(setClickedPageIndex({ index }));
  };
  const handleClick = (type) => {
    toggleDrawer(state.side, true);
    type === "manpower" && setOpen(!open);
    type === "approvals" && setOpenApprovals(!openApprovals);
    type === "organization" && setOpenOrganization(!openOrganization);
  };
  const setStepperToInitialState = () => {
    localStorage.setItem("createStepperIndex", 0);
  };

  const renderListItem = (
    link,
    message,
    title,
    icon,
    opacity,
    customLogoWidth
  ) => {
    return (
      <>
        <Link to={link} className="txt-white">
          <ListItem
            className="listItem"
            button
            selected={activePageIndex === link}
            onClick={(event) => handleListItemClick(event, link)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
              primary={message}
              className="primary_color bold_font"
            />
          </ListItem>
        </Link>
      </>
    );
  };


  const OrganizationDropDown = () => {
    return (
      <Fragment>
        {
          (isUserHasPermission("VIEW_ORGANIZATIONAL_STRUCTURE") ||
          isUserHasPermission("VIEW_JOB_TITLE") ||
          isSuperAdmin()) &&
          <ListItem
          button
          onClick={() => handleClick("organization")}
          className="listItem"
        >
          <ListItemIcon>
            <OrganizationalStructureIcon />
          </ListItemIcon>
          <ListItemText
            primary={messages.sideMenu.Organization}
            className="primary_color bold_font"
          />
          {openOrganization ? (
            <ExpandLess
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            />
          ) : (
            <ExpandMore
              style={{
                display: sideMenu.sideMenuContent,
                transition: "0.5s",
              }}
            />
          )}
        </ListItem>
        }
       
        <Collapse
          in={openOrganization}
          timeout="auto"
          unmountOnExit
        >
          {(isUserHasPermission("VIEW_ORGANIZATIONAL_STRUCTURE") ||
            isSuperAdmin()) &&
            renderListItem(
              "/OrganizationalStructure",
              messages.sideMenu.OrganizationalStructure,
              "Organizational Structure",
               "",
              0
            )}
          {(isUserHasPermission("VIEW_JOB_TITLE") || isSuperAdmin()) &&
            renderListItem("/positionChart", messages.sideMenu.positionChart)}
        </Collapse>
      </Fragment>
    );
  };


  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <List component="nav" aria-label="main mailbox folders">
        <Link to="/">
          <ListItem button>
            <div
              className="companyLogoSideMenu mb-4"
              style={{
                backgroundImage: `url(${
                  companyLogo ? companyLogo : logoPlaceholder
                })`,
              }}
            ></div>
          </ListItem>
        </Link>
        {renderListItem(
          "/",
          messages.sideMenu.Dashboard,
          "Dashboard",
          <DashboardIcon />,
          1
        )}
        <>
          <ListItem
            button
            onClick={() => handleClick("manpower")}
            className="listItem"
          >
            <ListItemIcon>
              <ManpowerRequestIcon />
            </ListItemIcon>
            <ListItemText
              primary={messages.sideMenu.ManpowerRequests}
              className="primary_color bold_font"
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {(isUserHasPermission("JOB_DETAILS") || isSuperAdmin()) && (
              <div onClick={setStepperToInitialState}>
                {renderListItem(
                  "/manpowerRequests",
                  messages.sideMenu.createNewRequest,
                  "Manpower requests",
                  "",
                  0
                )}
              </div>
            )}
            {(isSuperAdmin() ||
              isUserHasPermission("VIEW_PENDING_JOB_REQUESTS") ||
              isUserHasPermission("LIST_JOB_REQUESTS") ||
              isUserHasPermission("VIEW_CLOSED_JOB_REQUESTS")) &&
              renderListItem(
                "/viewAllRequests",
                messages.sideMenu.viewAllrequests,
                "Manpower requests",
                "",
                0
              )}
          </Collapse>
          <ListItem
            button
            onClick={() => handleClick("approvals")}
            className="listItem"
          >
            <ListItemIcon>
              <RequestIcon />
            </ListItemIcon>
            <ListItemText
              primary={messages.Requests.Requests}
              className="primary_color bold_font"
            />
            {openApprovals ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openApprovals} timeout="auto" unmountOnExit>
            {(isUserHasPermission("APPROVAL") || isSuperAdmin()) &&
              renderListItem(
                "/Requests",
                messages.sideMenu.agentsRequests,
                "Requests",
                "",
                0
              )}
            {(isUserHasPermission("APPROVE_CANDIDATE_REQUESTS") ||
              isSuperAdmin()) &&
              renderListItem(
                "/candidateApprovals",
                messages.sideMenu.candidatesRequests,
                "Candidates requests",
                "",
                0
              )}
          </Collapse>
          {(isUserHasPermission("LIST_ALL_JOB_OPENINGS") || isSuperAdmin()) &&
            renderListItem(
              "/jobOpenings",
              messages.sideMenu.Jobopening,
              "Job opening",
              <JobOpeningIcon />,
              1
            )}
          {(isUserHasPermission("MY_INTERVIRWS_GRID") || isSuperAdmin()) &&
            renderListItem(
              "/myInterviews",
              messages.myInterviews.myInterviews,
              "My interviews",
              <InterviewIcon />,
              1
            )}
          {(isUserHasPermission("VIEW_REPORTS") || isSuperAdmin()) &&
            renderListItem(
              "/report",
              messages.sideMenu.report,
              "Report",
              <ReportIcon />,
              1,
              "16px"
            )}
          {(isUserHasPermission("TALENT_POOL_LIST") || isSuperAdmin()) &&
            renderListItem(
              "/TalentPool",
              messages.sideMenu.TalentPool,
              "Talent Pool",
              <TalentPoolIcon />,
              1
            )}
          <OrganizationDropDown />
          {(isUserHasPermission("SETUP") || isSuperAdmin()) &&
            renderListItem(
              "/setup",
              messages.sideMenu.Settings,
              "Settings",
              <SettingIcon />,
              1
            )}
        </>
        {/* )} */}
      </List>
      <img src={logo} alt="HRCom logo" className={"logoSideNav m-3"} />
    </div>
  );
  return (
    <div>
      <Button className="drawerShown" onClick={toggleDrawer("left", true)}>
        <MenuIcon />
      </Button>
      <Drawer
        open={state.left}
        onClose={toggleDrawer("left", false)}
        dir={lang === "ar" ? "rtl" : "ltr"}
        className={lang === "ar" ? "text-right" : "text-left"}
      >
        {sideList("left")}
      </Drawer>
    </div>
  );
};

const DrawerMenuComponent = injectIntl(DrawerMenu);

export default DrawerMenuComponent;
