import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/Organization";
import API from "../../network/apis/Organization";
import { dispatchError ,showSnackbar} from "utils/shared";
import * as ACTION from "../actions/Organization";
import store from "store";

export function* getOrganizationGraph() {
  try {
    const response = yield call(API.requestOrganizationStructureList);
    store.dispatch(ACTION.recieveOrganizationStructure(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getPostionGraph() {
  try {
    const response = yield call(API.requestPositionsChartList);
    store.dispatch(ACTION.recievePositionCharts(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getActiveAgentsRequest() {
  try {
    const response = yield call(API.getActiveAgentsRequest);
    store.dispatch(ACTION.activeAgentsResponse(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getSalaryGradeRequest() {
  try {
    const response = yield call(API.getSalaryGradeRequest);
    store.dispatch(ACTION.salaryGradeResponse(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getCategoryListRequest() {
  try {
    const response = yield call(API.getCategoryListRequest);
    store.dispatch(ACTION.categoryListResponse(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* submitAddPositionRequest({payload}) {
  try {
    yield call(API.submitAddPositionRequest,payload);
    showSnackbar("savedSuccessfuly");
    store.dispatch(ACTION.OrganizationDataSubmited(true));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}

export function* submitAddDepartmentRequest({payload}) {
  try {
    yield call(API.submitAddDepartmentRequest,payload);
    showSnackbar("savedSuccessfuly");
    store.dispatch(ACTION.OrganizationDataSubmited(true));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}

export function* getDepartmentDataRequest({payload}) {
  try {
    const response =yield call(API.getDepartmentDataRequest,payload.id);
    store.dispatch(ACTION.clickedNodeData(response.data.data));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}


export function* editDepartmentRequest({payload}) {
  try {
    const response =yield call(API.editDepartmentRequest,payload);
    showSnackbar("savedSuccessfuly");
    store.dispatch(ACTION.OrganizationDataSubmited(true));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}

export function* deleteDepartmentRequest({payload}) {
  try {
    const response =yield call(API.deleteDepartmentRequest,payload);
    showSnackbar("done");
    store.dispatch(ACTION.requestOrganizationStructure());
    store.dispatch(ACTION.organizationDeletedAction(false));
  } catch (err) {
    store.dispatch(ACTION.organizationDeletedAction(false));
    dispatchError(err.response?.data);
  }
}

export function* getPositionDataRequest({payload}) {
  try {
    const response =yield call(API.getPositionDataRequest,payload.id);
    store.dispatch(ACTION.clickedNodeData(response.data.data));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}

export function* editPositionRequest({payload}) {
  try {
    const response =yield call(API.editPositionRequest,payload);
    showSnackbar("savedSuccessfuly");
    store.dispatch(ACTION.OrganizationDataSubmited(true));
  } catch (err) {
    store.dispatch(ACTION.OrganizationDataSubmited(false));
    dispatchError(err.response?.data);
  }
}

export function* deletePositionRequest({payload}) {
  try {
    const response =yield call(API.deletePositionRequest,payload);
    showSnackbar("done");
    store.dispatch(ACTION.requestPositionCharts());
    store.dispatch(ACTION.organizationDeletedAction(false));
  } catch (err) {
    store.dispatch(ACTION.organizationDeletedAction(false));
    dispatchError(err.response?.data);
  }
}

export function* OrganizationSagasWatch() {
  yield takeEvery(types.REQUEST_ORGANIZATION_STRUCTURE, getOrganizationGraph);
  yield takeEvery(types.REQUEST_POSITIONS_CHART,getPostionGraph);
  yield takeEvery(types.GET_ACTIVE_AGENTS_REQUEST,getActiveAgentsRequest)
  yield takeEvery(types.GET_SALARY_GRADE_REQUEST,getSalaryGradeRequest)
  yield takeEvery(types.GET_CATEGORY_LIST_REQUEST,getCategoryListRequest)
  yield takeEvery(types.SUBMIT_ADD_POSITION_REQUEST,submitAddPositionRequest)
  yield takeEvery(types.SUBMIT_ADD_DEPARTMENT_REQUEST,submitAddDepartmentRequest)
  yield takeEvery(types.GET_DEPARTMENT_DATA_REQUEST,getDepartmentDataRequest)
  yield takeEvery(types.EDIT_DEPARTMENT_REQUEST,editDepartmentRequest)
  yield takeEvery(types.DELETE_DEPARTMENT_REQUEST,deleteDepartmentRequest)
  yield takeEvery(types.GET_POSITION_DATA_REQUEST,getPositionDataRequest)
  yield takeEvery(types.EDIT_POSITION_REQUEST,editPositionRequest)
  yield takeEvery(types.DELETE_POSITION_REQUEST,deletePositionRequest)
}
