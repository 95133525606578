import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/recruitmentPlan";
import API from "../../network/apis/recruitmentPlan";
import { dispatchError, showSnackbar, showWarningSnackbar } from "utils/shared";
import * as ACTION from "../actions/recruitmentPlan";
import History from "routes/History";

export function* getRecruitmentPlan() {
  try {
    const response = yield call(API.getRecruitmentPlan);
    yield put(ACTION.getRecruitmentPlanRecieve(response.data.data));
  } catch (err) {
    err?.response?.data && dispatchError(err?.response?.data);
  }
}

export function* RecruitmentPlanRequest(action) {
  try {
    const response = yield call(API.setRecruitmentPlan, action.payload);
    if (response.data?.data?.length > 0) {
      showWarningSnackbar(
        response.data?.data.join(" / "),
        "warning"
      );
    } else {
      showSnackbar("savedSuccessfuly");
    }
  } catch (err) {
    err?.response?.data && dispatchError(err?.response?.data);
  }
}

export function* RecruitmentPlanSagasWatch() {
  yield takeEvery(types.RECRUITMENT_PLAN_REQUEST, RecruitmentPlanRequest);
  yield takeEvery(types.GET_RECRUITMENT_PLAN_REQUEST, getRecruitmentPlan);
}
