import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import Moment from 'react-moment';
import Timer from 'react-compound-timer';
import { ButtonComponent } from 'components/Controls/Button/Button';
import History from '../../routes/History';
import {FreeTrialIcon} from './SvgImages';
import { getRemainingPlanTime } from '../../utils/shared';
import './FreeTrailCountdown.scss';
import { getCompanySubscribtionEndDate_Request } from 'store/actions/payment';

function FreeTrailCountdown({ intl: { messages } }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanySubscribtionEndDate_Request());
  }, []);
  const {
    payment: { end_date },
  } = useSelector((state) => state);

  const lang = useSelector((state) => state.locale.lang);
  const plan = localStorage.getItem('plan');
  // const endDate = localStorage.getItem('subscription_end');
  const hours = getRemainingPlanTime(end_date).asHours();
  const renderTimeCoundDown = () => {
    return (
      <>
        <div className="d-flex justify-content-start align-items-center w-100">
          {/* <img
            src={freeTrialIcon}
            alt="free trial"
            className="free_trial_img d-none d-md-block"
          /> */}
          <FreeTrialIcon />
          <div className="d-flex justify-content-between align-items-center flex-column flex-md-row w-100 px-4 py-4 py-md-0">
            <div className="d-flex justify-content-center align-items-center flex-column flex-md-row">
              <p className="mb-3 mb-md-0 semi_bold_font free_trial_sentence">
                {hours > 0
                  ? messages.subscribtion.freeTrialEndsIn
                  : messages.subscribtion.freeTrialEndedIn}
                :
              </p>
              {hours > 0 ? (
                <div className="ends_in mx-3 mb-3 mb-md-0">
                  <Timer initialTime={hours * 3600000} direction="backward">
                    {() => (
                      <div className="d-flex">
                        <div className="mx-2 medium_font text-center days_count py-2 px-3">
                          <p className="semi_bold_font mb-1 time_count">
                            <Timer.Days />
                          </p>
                          <p className="medium_font time_label mb-0">
                            {messages.subscribtion.days}
                          </p>
                        </div>
                        <div className="mx-2 medium_font text-center hours_count py-2 px-3">
                          <p className="semi_bold_font mb-1 time_count">
                            <Timer.Hours />
                          </p>
                          <p className="medium_font time_label mb-0">
                            {messages.subscribtion.hours}
                          </p>
                        </div>
                      </div>
                    )}
                  </Timer>
                </div>
              ) : (
                <p className=" mb-0 mx-3 time_count">
                  <Moment
                    format={lang === 'ar' ? 'YYYY/MM/DD' : 'DD/MM/YYYY'}
                    locale={lang === 'ar' ? 'en' : 'en'}
                  >
                    {end_date}
                  </Moment>
                </p>
              )}
            </div>
            <div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {hours <= 48 && plan === 'trial' && (
        <div className="subscriptionCard mb-5 d-flex justify-content-between align-items-center">
          {renderTimeCoundDown()}
        </div>
      )}
    </>
  );
}

export default injectIntl(FreeTrailCountdown);
