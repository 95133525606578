import { call, put } from "redux-saga/effects";
import API from "../../network/apis/agentsAPI";
import APIDashboard from "../../network/apis/dashboard";
import * as ACTION from "../actions/agents";
import * as type from "../types/Agent";
import store from "../../store";
import history from "../../routes/History";
import {
  dispatchError,
  showSnackbar,
  pushToUrlNewParam,
  getDecodedAccessToken,
} from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import { receiveLogin } from "store/actions/login";
export function* addNewAgent(action) {
  try {
    const response = yield call(API.addNewAgent, action.payload.data);
    yield put(ACTION.receiveAddNewAgent(response.data.data));
    showSnackbar("savedSuccessfuly");
    action.payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAllAgents(action) {
  try {
    const response = yield call(API.getAllAgents, action.payload);
    yield put(ACTION.receiveGetAllAgents(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAgent(action) {
  try {
    const response = yield call(API.getAgent, action.payload);
    yield put(ACTION.receiveGetAgent(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAgentCandidateProfilePermissions(action) {
  try {
    const response = yield call(API.getAgentCandidateProfilePermissions, action.payload);
    yield put(ACTION.receiveAgentCandidateProfile(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAgentJobDetailsPermissions(action) {
  try {
    const response = yield call(API.getAgentJobDetailsPermissions, action.payload);
    yield put(ACTION.receiveAgentJobDetails(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* editAgent(action) {
  try {
    const response = yield call(API.editAgent, action.payload);
    yield put(ACTION.receiveUpdateAgent(response.data));
    showSnackbar("savedSuccessfuly");
    history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteAgent({
  payload: { agentId, agentsLength, activePage },
}) {
  try {
    const response = yield call(API.deleteAgent, agentId);
    yield put(ACTION.receiveDeleteAgent(response.data));
    store.dispatch(
      ACTION.requestGetAllAgents(
        agentsLength === 1 && activePage > 1 ? activePage - 1 : activePage
      )
    );
    pushToUrlNewParam(
      agentsLength === 1 && activePage > 1 ? activePage - 1 : activePage
    );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* activateDeactivateAgent(action) {
  try {
    const response = yield call(API.activateDeactivateAgent, action.payload);
    yield put(ACTION.receiveActivateDeactivateAgent(response.data));
    store.dispatch(
      ACTION.requestGetAllAgents(history.location.search.split("=")[1] || 1)
    );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addAgentPassword(action) {
  try {
    const response = yield call(API.addAgentPassword, action.payload);
    showSnackbar('done');
    yield put(receiveLogin(response.data.data));
    for (const property in response.data.data) {
      localStorage.setItem(property, response.data.data[property]);
    }
    const decode_token = getDecodedAccessToken(response.data.data.token);
    if (decode_token.action_permission !== undefined) {
      localStorage.setItem("permissions", decode_token.action_permission);
    }
    localStorage.setItem("roles", decode_token.role);
    if (localStorage.getItem("prevPath")) {
      history.push(localStorage.getItem("prevPath"));
    } else {
      /* Hide Subscription adaa  */
      // response.data.data.is_view_only === "true"
      //   ? history.push("/subscriptionPlans")
      //   : history.push("/");
      history.push("/");
    }
    localStorage.removeItem("prevPath");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* isAgentConfirmed(action) {
  try {
    const response = yield call(API.isAgentConfirmed, action.payload);
    yield put(ACTION.receiveAgentConfirmation(response.data));
    if (response.status === 201) {
      showSnackbar('done');
      history.push('/redirect-login');
    }
  } catch (err) {
    if (err.request.status === 404) {
      history.push("/notFound");
    }
    dispatchError(err?.response?.data);
  }
}

export function* getAgentProfile() {
  try {
    const response = yield call(API.getAgentProfile);
    yield put(ACTION.receiveGetAgentProfile(response.data.data));
    const {
      data: { data },
    } = response;
    localStorage.setItem("image_url", data.image_url);
    localStorage.setItem("first_name", data.first_name);
    localStorage.setItem("last_name", data.last_name);
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}

export function* editAgentProfile(action) {
  try {
    const response = yield call(API.editAgentProfile, action.payload);
    yield put(ACTION.receiveEditAgentProfile(response.data));
    showSnackbar("savedSuccessfuly");
    yield put(ACTION.requestGetAgentProfile());
    const {
      data: { data },
    } = response;
    localStorage.setItem("userProfile", data.image_url);
    localStorage.setItem("userName", `${data.first_name} ${data.last_name}`);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* changeAgentPassword(action) {
  try {
    const response = yield call(API.agentChangePassword, action.payload);
    yield put(ACTION.receiveChangeAgentPassword(response.data));
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* resendConfirmation({ payload }) {
  try {
    const resendConfirmationAPI = payload.isCompanyCreator
      ? APIDashboard.requestVerifyEmail : API.resendConfirmation;
      
    const response = yield call(resendConfirmationAPI, {
      user_id: payload.agentId,
    });
    yield put(ACTION.receiveResendConfirmation(response.data));
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* googleSyncRequest() {
  try {
    const response = yield call(API.googleSyncRequest);
    window.location.href=response.data.data
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* microsoftSyncRequest() {
  try {
    const response = yield call(API.microsoftSyncRequest);
    window.location.href=response.data.data
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteSyncModalRequest(){
  try {
    const response = yield call(API.deleteSyncModalRequest);
    yield put(ACTION.requestGetAgentProfile());
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* notAllowReminderRequest(){
  try {
    const response = yield call(API.notAllowReminderRequest);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* AgentSagasWatch() {
  yield takeLatest(type.ADD_NEW_AGENT_REQUEST, addNewAgent);
  yield takeLatest(type.GET_ALL_AGENTS_REQUEST, getAllAgents);
  yield takeLatest(type.GET_AGENT_REQUEST, getAgent);
  yield takeLatest(type.EDIT_AGENT_REQUEST, editAgent);
  yield takeLatest(type.DELETE_AGENT_REQUEST, deleteAgent);
  yield takeLatest(
    type.ACTIVATE_DEACTIVATE_AGENT_REQUEST,
    activateDeactivateAgent
  );
  yield takeLatest(type.ADD_AGENT_PASSWORD_REQUEST, addAgentPassword);
  yield takeLatest(type.IS_AGENT_CONFIRMED_REQUEST, isAgentConfirmed);
  yield takeLatest(type.GET_AGENT_PROFILE_REQUEST, getAgentProfile);
  yield takeLatest(type.EDIT_AGENT_PROFILE_REQUEST, editAgentProfile);
  yield takeLatest(type.AGENT_CHANGE_PASSWORD_REQUEST, changeAgentPassword);
  yield takeLatest(type.AGENT_RESEND_CONFIRMATION_REQUEST, resendConfirmation);
  yield takeLatest(type.GOOGLE_SYNC_REQUEST, googleSyncRequest);
  yield takeLatest(type.MICROSOFT_SYNC_REQUEST, microsoftSyncRequest);
  yield takeLatest(type.DELETE_SYNC_MODAL_REQUEST, deleteSyncModalRequest);
  yield takeLatest(type.NOT_ALLOW_REMNDER_REQUEST, notAllowReminderRequest);
  yield takeLatest(type.AGENT_CANDIDATE_PROFILE_REQUEST, getAgentCandidateProfilePermissions);
  yield takeLatest(type.AGENT_JOB_DETAILS_REQUEST, getAgentJobDetailsPermissions);

  
  
}
