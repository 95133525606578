import * as types from "../types/Agent";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE , action) => {
    let agentData = action.payload
    switch (action.type) {
        case types.ADD_NEW_AGENT_RECEIVE:
            return { ...state, agentData};
        case types.GET_ALL_AGENTS_RECEIVE:
            return { ...state, ...action.payload};
        case types.GET_AGENT_RECEIVE:
            return { ...state, ...action.payload};
        case types.EDIT_AGENT_RECEIVE:
            return { ...state, ...action.payload};
        case types.DELETE_AGENT_RECEIVE:
            return { ...state, ...action.payload};
        case types.ACTIVATE_DEACTIVATE_AGENT_RECEIVE:
            return { ...state, ...action.payload};
        case types.ADD_AGENT_PASSWORD_RECEIVE:
            return { ...state, ...action.payload};
        case types.IS_AGENT_CONFIRMED_RECEIVE:
            return { ...state, ...action.payload};
        case types.EDIT_AGENT_PROFILE_RECEIVE:
            return { ...state, ...action.payload};
        case types.AGENT_CHANGE_PASSWORD_RECEIVE:
            return { ...state, ...action.payload};
        case types.AGENT_CANDIDATE_PROFILE_RECEIVE:
                return { ...state, candidatePermissions:{...action.payload}};
        case types.AGENT_JOB_DETAILS_RECEIVE:
                    return { ...state, jobDetailsPermissions:{...action.payload}};
        default: return state;
    }
}