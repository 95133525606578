
/**
 *  Redirections from company Dashoboard to HRCOM Website
 *  Redirections from company Dashoboard to HRCOM Candidate Portal for specific Company
 */
import store from "../store";

export function getDomain() { 
  let host = window.location.host;
  let parts = host.split(".");
  let domain = parts[0].split(process.env.REACT_APP_COMPANY_BASE_URL)[0];
  const domain_name = process.env.REACT_APP_DOMAIN
    ? process.env.REACT_APP_DOMAIN
    : domain;
  const domain_name_val =  domain_name ;
  if(domain_name_val==='hrta'){
    localStorage.setItem("domain", "adaa");
  }else{
    localStorage.setItem("domain", domain_name_val);
  }

  if(domain_name_val==='hrta'){
    return "adaa"
  }else{
    return domain_name_val
  }
  
 
}
const getLocale = () => {
  return store.getState().locale?.lang;
};
export const getCandidatePortalHome = () => {
  return `https://${getDomain()}${process.env.REACT_APP_CANDIDATE_PORTAL_BASE_URL}`;
};
export const adminPortalRedirect = () => {
  return adminPortalRedirectWithParams(process.env.REACT_APP_ADMIN_URL);
};
const adminPortalRedirectWithParams = (url) => {
  return `${url}/redirect-company?locale=${getLocale()}&domain=${getDomain()}`;
};
export const getEnv = () => {
  return `.${process.env.REACT_APP_ENV}`;
};
export const jobSeekerUrl = () => {
  return process.env.REACT_APP_JOB_SEEKER_URL;
};

