import History from "routes/History";
import {
  executiveDirectorHasPermissionJobRequestApproval,
  executiveDirectorHasPermissionJobRequestEdit,
} from "containers/dashboard/JobRole/ExcecutiveDirectorPermissions";

export const handleNotificationRedirection = (notification) => {
  const { notification_type_id, item_id, meta_data } = notification;
  localStorage.setItem("active_stage_index", 0);
  switch (parseInt(notification_type_id)) {
    case 8:
      History.push(`/Requests`);
      break;
    case 9:
      History.push(`/candidateApprovals`);
      break;
    case 10:
      History.push(`/Requests`);
      break;
    case 11:
      History.push(`/viewAllRequests`);
      break;
    case 12:
      History.push(`/viewAllRequests?state=closed`);
      break;
    case 13:
      localStorage.setItem("active_stage_index", 4);
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 14:
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 15:
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 16:
      History.push(`/applicantProfile?candidate_id=${item_id}&tab=5`);
      break;
    case 17:
      History.push(`/applicantProfile?candidate_id=${item_id}`);
      break;
    case 18:
      History.push(`/applicantProfile?candidate_id=${item_id}&tab=3`);
      break;
    case 19:
      History.push(`/myInterviews?page=1&type=new`);
      break;
    case 20:
      History.push(`/myInterviews?page=1&type=new`);
      break;
    case 26:
      window.location.href = `/applicantProfile?candidate_id=${item_id}&tab=9`;
      break;
    case 27:
      localStorage.setItem("active_stage_index", 7);
      window.location.href = `/manageCandidates/${item_id}`;
      break;
    case 28:
      localStorage.setItem("active_stage_index", 7);
      window.location.href = `/manageCandidates/${item_id}`;
      break;
    case 29:
      History.push(`/Requests`);
    case 30:
      window.location.href = `/candidateApprovals`;
      break;
    case 32:
      // window.location.href = `/manageCandidates/${meta_data?.job_request_id}?page=1&stage_id=${meta_data?.stage_id}`;
      window.location.href = `/applicantProfile?candidate_id=${meta_data.candidate_id}&application_id=${meta_data.application_id}&from=job_opening&job_request_id=${meta_data.job_request_id}&tab=3`
      break;
    case 33:
      window.location.href = `/manageCandidates/${meta_data?.job_request_id}?page=1&stage_id=${meta_data?.stage_id}`;
      break;
    case 34:
      History.push(`/manageCandidates/${item_id}`);
      break;
    case 35:
      History.push(`/viewAllRequests?state=inprogress&page=1`);
      break;
    case 36:
      History.push(`/viewAllRequests?state=inprogress&page=1`);
      break;
    case 37:
      if (executiveDirectorHasPermissionJobRequestApproval) {
        window.location.href = `/viewAllRequests?state=inprogress&page=1`;
      }
      break;
    case 38:
      if (executiveDirectorHasPermissionJobRequestEdit()) {
        window.location.href = `/viewAllRequests?state=published&page=1`;
      }
      break;
    case 39:
      window.location.href = `/manageCandidates/${meta_data?.job_request_id}?page=1&stage_id=${meta_data?.stage_id}`;
      break;
  }
};
