import * as types from "../types/emailTemplates";

export const requestCustomizeEmailTemplate = (payload) => {
  return {
    type: types.CUSTOMIZE_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
};

export const recieveCustomizeEmailTemplate = (data) => {
  return {
    type: types.CUSTOMIZE_EMAIL_TEMPLATE_RECIEVE,
    payload: data,
  };
};

export const addCustomizeEmailTemplate = (data) => {
  return {
    type: types.ADD_EMAIL_TEMPLATE_REQUEST,
    payload: data,
  };
};
export const editTemplateEmailRequest = (payload) => {
  return {
    type: types.EDIT_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
};
export const editTemplateEmailRecieve = (payload) => {
  return {
    type: types.EDIT_EMAIL_TEMPLATE_RECIEVE,
    payload,
  };
};
export const deleteEmailTemplateRequest = (payload) => {
  return {
    type: types.DELETE_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
};
export const CustomizedEmailTemplatesPlaceholdersRequest = () => {
  return {
    type: types.GET_EMAIL_TEMPLATE_PLACEHOLDERS_REQUEST,
  };
};
export const CustomizedEmailTemplatesPlaceholdersRecieve = (payload) => {
  return {
    type: types.GET_EMAIL_TEMPLATE_PLACEHOLDERS_RECIEVE,
    payload,
  };
};
export const getCustomizeEmailTemplateListRequest = () => {
  return {
    type: types.GET_EMAIL_TEMPLATE_LIST_REQUEST,
  };
};
export const getCustomizeEmailTemplateListRecieve = (payload) => {
  return {
    type: types.GET_EMAIL_TEMPLATE_LIST_RECIEVE,
    payload,
  };
};
export const getCustomizeEmailTemplateRequest = (payload) => {
  return {
    type: types.GET_CANDIDATE_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
};
export const getCustomizeEmailTemplateRecieve = (payload) => {
  return {
    type: types.GET_CANDIDATE_EMAIL_TEMPLATE_RECIEVE,
    payload,
  };
};
export const updateCustomTemplateRequest = (payload) => {
  return {
    type: types.UPDATE_CUSTOM_EMAIL_TEMPLATE_REQUEST,
    payload,
  };
};
export const EmailTemplateIsDeleted = (payload) => {
  return {
    type: types.EMAIL_TEMPLATE_DELETED,
    payload,
  };
};



export const backToDefaultRequest = (payload) => {
  return {
    type: types.BACK_TO_DEFAULT_REQUEST,
    payload,
  };
};
export const backToDefaultRecieve = (payload) => {
  return {
    type: types.BACK_TO_DEFAULT_RECIEVE,
    payload,
  };
};


export const clearDefaultData = () => {
  return {
   type: types.CLEAR_DEFAULT 
  };
};
