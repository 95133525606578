import * as types from '../types/competenceSettings';

export const requestCompetenceSettings = () => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_REQUEST,
    };
  };
  
  export const receiveCompetenceSettings = (data) => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_RECEIVE,
      payload: data,
    };
  };


  export const requestUpdateCompetenceSettings = (data) => {
    return {
      type: types.UPDATE_COMPETENCE_SETTINGS_REQUEST,
      payload: data,
    };
  };
  
  export const receiveUpdateCompetenceSettings = (data) => {
    return {
      type: types.UPDATE_COMPETENCE_SETTINGS_RECEIVE,
      payload: data,
    };
  };


  export const requestAddCompetenceSettingsCategories = (data) => {
    return {
      type: types.ADD_COMPETENCE_SETTINGS_CATEGORIES_REQUEST,
      payload: data,
    };
  };



  export const requestAddCompetenceSettingsItems = (data) => {
    return {
      type: types.ADD_COMPETENCE_SETTINGS_ITEMS_REQUEST,
      payload: data,
    };
  };
  
  export const receiveAddCompetenceSettingsCategories = (data) => {
    return {
      type: types.ADD_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE,
      payload: data,
    };
  };


  export const requestDeleteCompetenceSettingsCategories = (data) => {
    return {
      type: types.DELETE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST,
      payload: data,
    };
  };
  
  export const receiveDeleteCompetenceSettingsCategories = (data) => {
    return {
      type: types.DELETE_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE,
      payload: data,
    };
  }



  export const requestDeleteCompetenceSettingsItems = (data) => {
    return {
      type: types.DELETE_COMPETENCE_SETTINGS_ITEMS_REQUEST,
      payload: data,
    };
  };
  
  export const receiveDeleteCompetenceSettingsItems = (data) => {
    return {
      type: types.DELETE_COMPETENCE_SETTINGS_ITEMS_RECEIVE,
      payload: data,
    };
  }



  export const requestGetCompetenceSettingsCategories = (data) => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_CATEGORIES_REQUEST,
      payload: data,
    };
  };
  
  export const receiveGetCompetenceSettingsCategories = (data) => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE,
      payload: data,
    };
  }


  export const requestUpdateCompetenceCategoriesSettings = (data) => {
    return {
      type: types.UPDATE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST,
      payload: data,
    };
  };



  
  export const requestUpdateCompetenceItemsSettings = (data) => {
    return {
      type: types.UPDATE_COMPETENCE_SETTINGS_ITEMS_REQUEST,
      payload: data,
    };
  };




  export const requestGetCompetenceSettingsCategoriesHaveItems = (data) => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_REQUEST,
      payload: data,
    };
  };
  
  export const receiveGetCompetenceSettingsCategoriesHaveItems = (data) => {
    return {
      type: types.GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_RECEIVE,
      payload: data,
    };
  }

  export const requestGetCompetenceLookup = (data) => {
    return {
      type: types.GET_COMPETENCE_LOOKUP_REQUEST,
      payload: data,
    };
  };
  
  export const receiveGetCompetenceLookup = (data) => {
    return {
      type: types.GET_COMPETENCE_LOOKUP_RECEIVE,
      payload: data,
    };
  }