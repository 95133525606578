import { axiosInstance } from "./config";

let handlerEnabled = true;

const requestOrganizationStructureList = async () => {
  return await axiosInstance.get(`/admin/OrganizationalStructureDepartment`, {
    handlerEnabled
  });
};

const requestPositionsChartList = async () => {
  return await axiosInstance.get(`/admin/job_title`, {
    handlerEnabled
  });
};

const getActiveAgentsRequest= async () => {
  return await axiosInstance.get(`/admin/agent/activeAgents/lookup`, {
    handlerEnabled
  });
};

const getSalaryGradeRequest= async () => {
  return await axiosInstance.get(`admin/salary_grade/Lookup/get`, {
    handlerEnabled
  });
};

const getCategoryListRequest= async () => {
  return await axiosInstance.get(`admin/job_title/categories/lookup`, {
    handlerEnabled
  });
};

const submitAddPositionRequest= async (data) => {
  return await axiosInstance.post(`admin/job_title`,data, {
    handlerEnabled
  });
};

const submitAddDepartmentRequest= async (data) => {
  return await axiosInstance.post(`admin/OrganizationalStructureDepartment`,data, {
    handlerEnabled
  });
};
const getDepartmentDataRequest= async (id) => {
  return await axiosInstance.get(`admin/organizationalStructureDepartment/getDepartmentDetails/${id}`, {
    handlerEnabled
  });
};

const editDepartmentRequest= async (data) => {
  return await axiosInstance.put(`/admin/organizationalStructureDepartment/updateDepartment/${data.id}`,data, {
    handlerEnabled
  });
};

const deleteDepartmentRequest= async (data) => {
  return await axiosInstance.delete(`/admin/organizationalStructureDepartment/deleteDepartment/${data.id}`, {
    handlerEnabled
  });
};

const getPositionDataRequest= async (id) => {
  return await axiosInstance.get(`admin/job_title/${id}`, {
    handlerEnabled
  });
};

const editPositionRequest= async (data) => {
  return await axiosInstance.put(`admin/job_title/${data.id}`,data, {
    handlerEnabled
  });
};
const deletePositionRequest= async (data) => {
  return await axiosInstance.delete(`admin/job_title/${data.id}`, {
    handlerEnabled
  });
};

export default {
  requestOrganizationStructureList,
  getActiveAgentsRequest,
  getSalaryGradeRequest,
  getCategoryListRequest,
  submitAddPositionRequest,
  submitAddDepartmentRequest,
  getDepartmentDataRequest,
  editDepartmentRequest,
  deleteDepartmentRequest,
  requestPositionsChartList,
  getPositionDataRequest,
  editPositionRequest,
  deletePositionRequest
};
