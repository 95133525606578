import { axiosInstance } from "./config";
import qs from "qs";

let handlerEnabled = true;

const requestRequestsInProgress = async (pageNumber) =>
  await axiosInstance.get(`/admin/job_request/list/pending`, {
    params: {
      page_number: pageNumber ? pageNumber : 1,
      page_size: 10,
    },
    handlerEnabled,
  });
const deleteRequestInprogress = async (requestId) =>
  await axiosInstance.delete(`/admin/job_request/${requestId}`, {
    handlerEnabled,
  });

const requestPublishedJobs = async (pageNumber) =>
  await axiosInstance.get(`/admin/job_request/list/published`, {
    params: {
      page_number: pageNumber ? pageNumber : 1,
      page_size: 10,
    },
    handlerEnabled,
  });
const requestRequestsClosed = async (pageNumber) =>
  await axiosInstance.get(`/admin/job_request/list/cLosed`, {
    params: {
      page_number: pageNumber ? pageNumber : 1,
      page_size: 10,
    },
    handlerEnabled,
  });
const requestAllJobOpenings = async ({ pageNumber, params ,is_archived}) => {
  return await axiosInstance.get(`/admin/job_request/list/all_job_openings`, {
    params: {
      page_number: pageNumber ? pageNumber : 1,
      page_size: 10,
      ...(params?.status?.length > 0 && { status: params.status }),
      ...(params?.job_title_id?.length > 0 && {
        job_title_id: params.job_title_id,
      }),
      ...(params?.branch_id?.length > 0 && { branch_id: params.branch_id }),
      is_archived:is_archived,
      candidate_name:params.candidate_name,
      reference_number:params.reference_number,
      job_title:params.job_title,
      ...(params?.status && { status: params.status })
    },
   
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    handlerEnabled,
  });
};

const delayClosingDate = async (data) =>
  await axiosInstance.post(`/admin/job_request/delay_closing_date`, data, {
    handlerEnabled,
  });

const requestApprovalStatusFlow = async (id) =>
  await axiosInstance.get(`/admin/job_request/approval_status/${id}`, {
    handlerEnabled,
  });

const requestCompanyInterviewMinutes = async (id) => {
  return await axiosInstance.get(`/admin/job_request/Get_CompanyMinutesData`, {
    handlerEnabled,
  });
};

const checkJobsLimit = async () =>
  await axiosInstance.get(`/admin/job_request/disable_add`, {
    handlerEnabled,
  });

const closeJobOpening = async (id) =>
  await axiosInstance.put(`/admin/job_request/close`, JSON.stringify(id), {
    handlerEnabled,
  });

const holdJobOpening = async (id) =>
  await axiosInstance.put(`/admin/job_request/holdJob`, JSON.stringify(id), {
    handlerEnabled,
  });

const reOpenJobOpening = async (id) =>
  await axiosInstance.put(`/admin/job_request/reopenJob`, JSON.stringify(id), {
    handlerEnabled,
  });

const getHoldingJobsList = async (page_number) =>
  await axiosInstance.get(`/admin/job_request/list/holded`, {
    params: {
      page_number: page_number ? page_number : 1,
      page_size: 10,
    },
    handlerEnabled,
  });

const duplicateJobPost = async (id) =>
  await axiosInstance.post(
    `/admin/job_request/duplicate_job`,
    JSON.stringify(id),
    {
      handlerEnabled,
    }
  );
const firstViewALLRequestJobOpeningRequest = async () =>
  await axiosInstance.get(`/admin/job_request/count`, {
    handlerEnabled,
  });

  const toggleArchive = async (data) =>
  await axiosInstance.post(`/admin/job_request/${data}/toggle_archiving`, {
    handlerEnabled,
  });

export default {
  requestRequestsInProgress,
  deleteRequestInprogress,
  requestPublishedJobs,
  requestAllJobOpenings,
  delayClosingDate,
  requestRequestsClosed,
  requestApprovalStatusFlow,
  requestCompanyInterviewMinutes,
  checkJobsLimit,
  closeJobOpening,
  holdJobOpening,
  reOpenJobOpening,
  getHoldingJobsList,
  duplicateJobPost,
  firstViewALLRequestJobOpeningRequest,
  toggleArchive

};
