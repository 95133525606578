import { axiosInstance } from "./config";

let handlerEnabled = true;

const addNewAgent = async data => {
  return await axiosInstance.post(`/admin/agent/add`, data, { handlerEnabled });
};

const getAllAgents = async pageNumber => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10
  };
  return await axiosInstance.get(`/admin/agent/list`, {
    params,
    handlerEnabled
  });
};

const getAgent = async id => {
  return await axiosInstance.get(`/admin/agent/${id}`, { handlerEnabled });
};

const editAgent = async data => {
  return await axiosInstance.put(`/admin/agent/update`, data, {
    handlerEnabled
  });
};

const deleteAgent = async id => {
  return await axiosInstance.delete(`/admin/agent/delete/${id}`, {
    handlerEnabled
  });
};

const activateDeactivateAgent = async data => {
  return await axiosInstance.put(`/admin/agent/activate`, data, {
    handlerEnabled
  });
};

const addAgentPassword = async data => {
  return await axiosInstance.post(`/admin/agent/password`, data, {
    handlerEnabled
  });
};

const isAgentConfirmed = async data => {
  return await axiosInstance.post(`/admin/agent/is_confirmed`, data, {
    handlerEnabled
  });
};

const getAgentProfile = async () => {
  return await axiosInstance.get(`/admin/agent/profile`, { handlerEnabled });
};

const editAgentProfile = async data => {
  return await axiosInstance.put(`/admin/agent/profile`, data, {
    handlerEnabled
  });
};

const agentChangePassword = async data => {
  return await axiosInstance.put(`/admin/agent/change_password`, data, {
    handlerEnabled
  });
};

const resendConfirmation = async ({ user_id }) => {
  return await axiosInstance.post(
    `/admin/agent/resend_confirmation/${user_id}`,
    { handlerEnabled }
  );
};

const googleSyncRequest = async () => {
  return await axiosInstance.get(
    `/admin/agent/email_sync/google/auth_url`
  );
};

const microsoftSyncRequest= async () => {
  return await axiosInstance.get(
    `/admin/agent/email_sync/microsoft/auth_url`
  );
};

const getAgentCandidateProfilePermissions= async () => {
  return await axiosInstance.get(
    `/admin/agent/candidate_profile_permissions`
  );
};

const getAgentJobDetailsPermissions= async () => {
  return await axiosInstance.get(
    `/admin/agent/job_details_permissions`
  );
};
const deleteSyncModalRequest= async () => {
  return await axiosInstance.delete(
    `/admin/agent/email_sync`
  );
};

const notAllowReminderRequest= async () => {
  return await axiosInstance.put(
    `/admin/agent/email_sync/not_allow_reminder`,null
  );
};

export default {
  addNewAgent,
  getAllAgents,
  getAgent,
  editAgent,
  deleteAgent,
  activateDeactivateAgent,
  addAgentPassword,
  isAgentConfirmed,
  getAgentProfile,
  editAgentProfile,
  agentChangePassword,
  resendConfirmation,
  googleSyncRequest,
  microsoftSyncRequest,
  deleteSyncModalRequest,
  notAllowReminderRequest,
  getAgentCandidateProfilePermissions,
  getAgentJobDetailsPermissions
};
