import * as types from "../types/videoInterview";

export const startInterviewRequest = (payload) => ({
  type: types.START_INTERVIEW_REQUEST,
  payload,
});
export const startInterviewReceive = (payload) => ({
  type: types.START_INTERVIEW_RECEIVE,
  payload,
});
export const finishInterviewRequest = (payload) => ({
  type: types.FINISH_INTERVIEW_REQUEST,
  payload,
});

export const finishInterviewReceive = (payload) => ({
  type: types.FINISH_INTERVIEW_RECEIVE,
  payload,
});
export const handleSubscription = payload => ({
  type: types.HANDLE_SUBSCRIPTION,
  payload
})
export const interviewDurationRequest = payload => ({
  type: types.INTERVIEW_DURATION_REQUEST,
  payload
})

export const setInterveChatMessages = payload => ({
  type: types.INTERVIEW_CHAT_MESSAGES,
  payload
})

export const toggleInterviewReviewModal = payload => ({
  type: types.TOGGLE_INTERVIEW_REVIEW_MODAL,
  payload
})
export const resetEvaluationForm = payload => ({
  type: types.RESET_EVALUATION_FORM,
  payload
})
export const endSessionRequest = payload => ({
  type: types.END_SESSION_REQUEST,
  payload
})

export const endSessionRecieve = payload => ({
  type: types.END_SESSION_RECIEVE,
  payload
})

export const disableFinishInterviewBtn = payload => ({
  type: types.DISABLE_FINISH_INTERVIEW_BTN,
  payload
})

export const leaveInterviewRequest = payload => ({
  type: types.LEAVE_INTERVIEW,
  payload
})


export const evalutionTypesRequest = (payload) => ({
  type: types.EVALUTION_TYPES_REQUEST,
  payload,
});
export const evalutionTypesReceive = (payload) => ({
  type: types.EVALUTION_TYPES_RECEIVE,
  payload,
});