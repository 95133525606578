import * as types from "../types/dashboard";
import { manipulateData } from "containers/Report/utils";

export default (
  state = {
    manipulateData: {},
    report: {},
    agentRequestedConfirmEmail: false,
    opened_job_posts: 0,
    closed_job_posts: 0
  },
  action
) => {
  switch (action.type) {
    case types.DASHBOARD_CARDS_DATA_RECEIVE:
      return { ...state, ...action.payload };
    case types.REPORT_DATA_RECIEVE:
      return {
        ...state,
        report: action.payload,
        manipulateData: manipulateData(action.payload),
      };
    case types.VERIFY_EMAIL_RECIEVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
