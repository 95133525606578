import { call, put } from "redux-saga/effects";
import API from "../../network/apis/apis";
import * as ACTION from "../actions/jobRoles";
import store from "../../store";
import history from "../../routes/History";
import {
  dispatchError,
  showSnackbar,
  pushToUrlNewParam
} from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/jobRoleSetup";
import History from "../../routes/History";

export function* getAllJobRoles(action) {
  try {
    const response = yield call(API.getAllJobRoles, action.payload);
    yield put(ACTION.receiveAllJobRoles(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addNewJobRole(action) {
  try {
    const response = yield call(API.addJobRole, action.payload.data);
    yield put(ACTION.receiveAddJobRole(response.data));
    showSnackbar("savedSuccessfuly");
    action.payload.type !== "saveNew" && history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* updateJobRole(action) {
  try {
    const response = yield call(API.editJobRole, action.payload);
    yield put(ACTION.receiveEditJobRole(response.data));
    showSnackbar("savedSuccessfuly");
    history.goBack();
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteJobRole({
  payload: { agentId, activePage, jobsLength }
}) {
  try {
    const response = yield call(API.deleteJobRole, agentId);
    yield put(ACTION.receiveDeleteJobRole(response.data));
    store.dispatch(
      ACTION.requestAllJobRoles(
        jobsLength === 1 && activePage > 1 ? activePage - 1 : activePage
      )
    );
    pushToUrlNewParam(
      jobsLength === 1 && activePage > 1 ? activePage - 1 : activePage
    );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getAJobRole(action) {
  try {
    const response = yield call(API.getJobRole, action.payload);
    yield put(ACTION.receiveGetJobRole(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* approvalSetup(action) {
  try {
    const response = yield call(API.getApprovalSetup, action.payload);
    yield put(ACTION.approvalSetupRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* saveApprovalSetup(action) {
  try {
    const response = yield call(API.saveApprovalSetup, action.payload);
    yield put(ACTION.saveApprovalSetupRecieve(response.data.data));
    store.dispatch(ACTION.approvalSetupRequest());
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* jobRolesWithApprovals() {
  try {
    const response = yield call(API.jobRolesWithApprovals);
    yield put(ACTION.jobRolesWithApprovalRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* salaryRangeRequest() {
  try {
    const response = yield call(API.salaryRangeRequest);
    yield put(ACTION.salaryRangeResponse(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* submitApprovalSetupRequest(action) {
  try {
    yield call(API.submitApprovalSetupRequest, action.payload);
    showSnackbar("savedSuccessfuly");
    History.push('/setup')
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* approvalSetupDataRequest() {
  try {
    const response = yield call(API.approvalSetupDataRequest);
    yield put(ACTION.approvalSetupDataResponse(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* JobRolesSagasWatch() {
  yield takeLatest(type.GET_ALL_JOB_ROLES_REQUEST, getAllJobRoles);
  yield takeLatest(type.ADD_JOB_ROLE_REQUEST, addNewJobRole);
  yield takeLatest(type.EDIT_JOB_ROLE_REQUEST, updateJobRole);
  yield takeLatest(type.GET_JOB_ROLE_REQUEST, getAJobRole);
  yield takeLatest(type.DELETE_JOB_ROLE_REQUEST, deleteJobRole);
  yield takeLatest(type.APPROVAL_SETUP_REQUEST, approvalSetup);
  yield takeLatest(type.SAVE_APPROVAL_SETUP_REQUEST, saveApprovalSetup);
  yield takeLatest(type.JOB_ROLES_WITH_APPROVAL_REQUEST, jobRolesWithApprovals);
  yield takeLatest(type.SALARY_RANGE_REQUEST, salaryRangeRequest);
  yield takeLatest(type.SUBMIT_APPROVAL_SETUP_REQUEST, submitApprovalSetupRequest);
  yield takeLatest(type.APPROVAL_SETUP_DATA_REQUEST, approvalSetupDataRequest);
  
}
