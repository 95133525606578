import { axiosInstance } from "./config";

let handlerEnabled = true;

const startInterviewRequest = async (id) => {
  return await axiosInstance.get(`/admin/agent_interview/start/${id}`, {
    handlerEnabled,
  });
};
const finishInterviewRequest = async ({
  comment, 
  interview_id,
  candidate_status,
  fileObj,
  stars_evaluations,
  percentage_evaluations,
  candidate_evaluation_status
}) => {
  return await axiosInstance.post(
    `/admin/agent_interview/rate`,
    {
      comment,
      stars_evaluations,
      percentage_evaluations,
      interview_id,
      candidate_status,
      candidate_evaluation_status:Number(candidate_evaluation_status),
      file: fileObj.file,
      file_extention: fileObj.fileType,
      file_name: fileObj.fileName,
    },
    {
      handlerEnabled,
    }
  );
};
const interviewDurationRequest = async ({ id, min }) => {
  let params = {
    consumed_minutes: min,
  };
  return await axiosInstance.put(
    `/admin/agent_interview/consumed_Minutes_InEndInterview/${id}`,
    {},
    { params, handlerEnabled }
  );
};
const endSession = async (data) => {
  return await axiosInstance.post(`/admin/agent_interview/end`, data, {
    handlerEnabled,
  });
};
const leaveInterviewRequest = async (data) => {
  return await axiosInstance.post(`/admin/agent_interview/leave`, data, {
    handlerEnabled,
  });
};
const requestEvalutionTypes = async () =>
  await axiosInstance.get(`/admin/agent_interview/evaluation_types`, {
    handlerEnabled,
  });
export default {
  startInterviewRequest,
  finishInterviewRequest,
  interviewDurationRequest,
  endSession,
  leaveInterviewRequest,
  requestEvalutionTypes
};
