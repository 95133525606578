export const ADD_NEW_AGENT_REQUEST = 'ADD_NEW_AGENT_REQUEST';
export const ADD_NEW_AGENT_RECEIVE = 'ADD_NEW_AGENT_RECEIVE';
export const GET_ALL_AGENTS_REQUEST = 'GET_ALL_AGENTS_REQUEST';
export const GET_ALL_AGENTS_RECEIVE = 'GET_ALL_AGENTS_RECEIVE';
export const GET_AGENT_REQUEST = 'GET_AGENT_REQUEST';
export const GET_AGENT_RECEIVE = 'GET_AGENT_RECEIVE';
export const EDIT_AGENT_REQUEST = 'EDIT_AGENT_REQUEST';
export const EDIT_AGENT_RECEIVE = 'EDIT_AGENT_RECEIVE';
export const DELETE_AGENT_REQUEST = 'DELETE_AGENT_REQUEST';
export const DELETE_AGENT_RECEIVE = 'DELETE_AGENT_RECEIVE';
export const ACTIVATE_DEACTIVATE_AGENT_REQUEST = 'ACTIVATE_DEACTIVATE_AGENT_REQUEST';
export const ACTIVATE_DEACTIVATE_AGENT_RECEIVE = 'ACTIVATE_DEACTIVATE_AGENT_RECEIVE';
export const ADD_AGENT_PASSWORD_REQUEST = 'ADD_AGENT_PASSWORD_REQUEST';
export const ADD_AGENT_PASSWORD_RECEIVE = 'ADD_AGENT_PASSWORD_RECEIVE';
export const IS_AGENT_CONFIRMED_REQUEST = 'IS_AGENT_CONFIRMED_REQUEST';
export const IS_AGENT_CONFIRMED_RECEIVE = 'IS_AGENT_CONFIRMED_RECEIVE';
export const GET_AGENT_PROFILE_REQUEST = 'GET_AGENT_PROFILE_REQUEST';
export const GET_AGENT_PROFILE_RECEIVE = 'GET_AGENT_PROFILE_RECEIVE';
export const EDIT_AGENT_PROFILE_REQUEST = 'EDIT_AGENT_PROFILE_REQUEST';
export const EDIT_AGENT_PROFILE_RECEIVE = 'EDIT_AGENT_PROFILE_RECEIVE';
export const AGENT_CHANGE_PASSWORD_REQUEST = 'AGENT_CHANGE_PASSWORD_REQUEST';
export const AGENT_CHANGE_PASSWORD_RECEIVE = 'AGENT_CHANGE_PASSWORD_RECEIVE';
export const AGENT_RESEND_CONFIRMATION_REQUEST = 'AGENT_RESEND_CONFIRMATION_REQUEST';
export const AGENT_RESEND_CONFIRMATION_RECEIVE = 'AGENT_RESEND_CONFIRMATION_RECEIVE';
export const GOOGLE_SYNC_REQUEST="GOOGLE_SYNC_REQUEST"
export const MICROSOFT_SYNC_REQUEST="MICROSOFT_SYNC_REQUEST"
export const DELETE_SYNC_MODAL_REQUEST="DELETE_SYNC_MODAL_REQUEST"
export const NOT_ALLOW_REMNDER_REQUEST="NOT_ALLOW_REMNDER_REQUEST"

export const AGENT_CANDIDATE_PROFILE_REQUEST='AGENT_CANDIDATE_PROFILE_REQUEST'
export const AGENT_CANDIDATE_PROFILE_RECEIVE="AGENT_CANDIDATE_PROFILE_RECEIVE"

export const AGENT_JOB_DETAILS_REQUEST='AGENT_JOB_DETAILS_REQUEST'
export const AGENT_JOB_DETAILS_RECEIVE="AGENT_JOB_DETAILS_RECEIVE"
