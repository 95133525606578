import { axiosInstance } from "./config";

let handlerEnabled = true;

const getApplicantProfile = async (candidate_id) => {
  return await axiosInstance.get(`/admin/candidates/${candidate_id}`, {
    handlerEnabled,
  });
};
const requestCandidateScreening = async ({
  job_request_id,
  application_id,
}) => {
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applicant_profile_applicationAnswers/${application_id}`,
    { handlerEnabled }
  );
};
const requestApplicantMainData = async ({ job_request_id, application_id }) => {
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/navigation`,
    { handlerEnabled }
  );
};


const requestCandidateMainData = async ({  candidate_id ,params}) => {
  return await axiosInstance.get(
    `/admin/talent_Pool/navigation/${candidate_id}`,{params:{...params}},
    { handlerEnabled }
  );
};
const requestCandidateApplications = async ({ candidate_id, page_number }) => {
  return await axiosInstance.get(
    `/admin/candidates/${candidate_id}/application`,
    {
      params: {
        page_number,
        page_size: 10,
      },
      handlerEnabled,
    }
  );
};
const requestApplicantInterviews = async ({ candidate_id, application_id }) => {
  let params = {
    candidate_application_id: application_id,
  };
  return await axiosInstance.get(
    `/admin/candidates/Interviews/${candidate_id}`,
    { params, handlerEnabled }
  );
};

const requestPreofferAttachements = async (candidate_id) => {
  return await axiosInstance.get(
    `/admin/candidates/${candidate_id}/attachments`,
    { handlerEnabled }
  );
};
const requestCandidateLog = async ({ job_request_id, application_id }) => {
  return await axiosInstance.get(
    `/admin/job_requests/${job_request_id}/applications/${application_id}/logs`,
    { handlerEnabled }
  );
};

const requestAllCandidateLogs = async (candidate_id) => {
  return await axiosInstance.get(`/admin/candidates/${candidate_id}/logs`, {
    handlerEnabled,
  });
};

const disableAdvert = async () =>
  await axiosInstance.get(`/admin/subscriptions/disable_Advert_questions`);

const getMoreInfoAnswers = async (candidate_id) => {
  return await axiosInstance.get(
    `/admin/moreInformationRequest/view_candidate_answers/${candidate_id}`,
    { handlerEnabled }
  );
};

const getCandidateOffers = async ({
  candidate_id,
  page_number
}) => {
  return await axiosInstance.get(`/admin/candidates/${candidate_id}/offers`, {
    params: {
      page_number,
      page_size: 10,
    },
    handlerEnabled,
  });
};
const getCandidateContracts = async ({
  candidate_id,
  page_number
}) => {
  return await axiosInstance.get(`/admin/candidates/${candidate_id}/contracts`, {
    params: {
      page_number,
      page_size: 10,
    },
    handlerEnabled,
  });
};

const disableSendMoreInfoRequest = async () => {
  return await axiosInstance.get(
    "/admin/subscriptions/disable_send_more_info_request"
  );
};
const disableSendEmailRequest = async () => {
  return await axiosInstance.get("/admin/subscriptions/disable_send_email");
};

const get_contractFileBytes = async ({ id }) =>
  await axiosInstance.get(
    `/admin/candidates/get_contractFileBytes/${id}`
  );
export default {
  getApplicantProfile,
  requestCandidateScreening,
  requestApplicantMainData,
  requestCandidateApplications,
  requestCandidateMainData,
  requestPreofferAttachements,
  requestApplicantInterviews,
  requestCandidateLog,
  requestAllCandidateLogs,
  disableAdvert,
  getMoreInfoAnswers,
  getCandidateOffers,
  disableSendEmailRequest,
  disableSendMoreInfoRequest,
  getCandidateContracts,
  get_contractFileBytes
};
